.react-calendar {
  width: 350px;
  max-width: 100%;
  background: #fff;
  line-height: 1.125em;
  color: #2C2C2C;
  padding: 10px;
}
.dark_mode .react-calendar{
  color: rgba(255, 255, 255, 0.6);
  background: #323F4B;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #2C2C2C;
}
.dark_mode .react-calendar__navigation button{
  color: rgba(255, 255, 255, 0.6);

}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f7f7f7;
}
.dark_mode .react-calendar__navigation button:enabled:hover,
.dark_mode .react-calendar__navigation button:enabled:focus {
  background-color: #1F2933;
}

.react-calendar__navigation button[disabled] {
  background-color: #f7f7f7;
}
.dark_mode .react-calendar__navigation button[disabled] {
  background-color: #1F2933;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #B24C63;
}
.dark_mode .react-calendar__month-view__days__day--weekend {
  color: #B24C63;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #2C2C2C;
}
.dark_mode .react-calendar__month-view__days__day--neighboringMonth {
  color: rgba(255, 255, 255, 0.87);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
  border-radius: 0%;
  height: auto;


}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  background: none;
  height: 47px;
  border-radius: 50%;
  padding: 0.75em 0.5em;
  border: 1px solid transparent !important;
  color: #2C2C2C;
}

.react-calendar__tile--range{
  border-radius: 0px;
  border: 0px solid transparent !important;
}
.react-calendar__tile--rangeStart{
  border-radius: 50% 0 0% 50%;

}
.react-calendar__tile--rangeEnd{
  border-radius: 0 50% 50% 0;
}
.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd{
  border-radius: 50%;
}

.dark_mode .react-calendar__tile{
  color: rgba(255, 255, 255, 0.6);
}
.react-calendar__tile:disabled {
  background-color: #f7f7f7;
}
.dark_mode .react-calendar__tile:disabled {
  background-color: #1F2933;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #f7f7f7;
  color: #2C2C2C;
}
.dark_mode .react-calendar__tile:enabled:hover,
.dark_mode .react-calendar__tile:enabled:focus {
  background-color: #1F2933;
  color: rgba(255, 255, 255, 0.6);
}


.react-calendar__tile--now {
  background: #eeeeee;
}
.dark_mode .react-calendar__tile--now {
  background: #192129;
}

.dark_mode .react-calendar__tile--now:enabled:hover,
.dark_mode .react-calendar__tile--now:enabled:focus {
  background: #1F2933;
  color: rgba(255, 255, 255, 0.6);
}
.dark_mode .react-calendar__tile--now:enabled:hover,
.dark_mode .react-calendar__tile--now:enabled:focus {
  background: #1F2933;
  color: rgba(255, 255, 255, 0.6);
}

.react-calendar__tile--hasActive {
  background: #4EE8C3;

  color:#fff;
}

.react-calendar__tile--active.react-calendar__tile--range{
  background: #4EE8C3 !important;
}

.dark_mode .react-calendar__tile--hasActive {
  background: #4EE8C3;

  color:rgba(255, 255, 255, 0.6);
}
.dark_mode .react-calendar__tile--active.react-calendar__tile--range{
  background: #028FA3 !important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #4EE8C3;

  color:#fff;
}

.dark_mode .react-calendar__tile--hasActive:enabled:hover,
.dark_mode .react-calendar__tile--hasActive:enabled:focus {
  background: #4EE8C3;

  color:rgba(255, 255, 255, 0.6);
}
.react-calendar__tile--active {
  background: #4EE8C3;

  color:#fff !important;

}
.dark_mode .react-calendar__tile--active {
  background: #4EE8C3;

  color:rgba(255, 255, 255, 0.6);
}


.dark_mode .react-calendar__tile--active:enabled:hover,
.dark_mode .react-calendar__tile--active:enabled:focus {
  background: #4EE8C3;

}
.dark_mode .react-calendar__tile--active:enabled:hover,
.dark_mode .react-calendar__tile--active:enabled:focus {
  background: #4EE8C3;

  color:rgba(255, 255, 255, 0.6);
}
.dm-calendar-dot-container{
  margin: 3px auto -8px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dm-transparent-dot{
  background: transparent;
  width: 5px;
  height: 5px;
  margin: 3px auto -8px;
  border-radius: 50%;
}

.dm-total-mailers-sent-dot{
  background: #4EE8C3;
  width: 5px;
  height: 5px;
  margin: 0px 2px;
  border-radius: 50%;
}
.dark_mode .dm-total-mailers-sent-dot{
  background: #00897B;
}
.dm-total-mailers-to-send-dot{
  background: #B24C63;
  width: 5px;
  height: 5px;
  margin: 0px 2px;
  border-radius: 50%;
}
.dark_mode .dm-total-mailers-to-send-dot{
  background: #B24C63;
}

.dm-total-mailers-in-queue-dot{
  background: #B24C63;
  width: 5px;
  height: 5px;
  margin: 0px 2px;
  border-radius: 50%;
}
.dark_mode .dm-total-mailers-in-queue-dot{
  background: #B24C63;
}

.react-calendar__tile--active .dm-total-mailers-sent-dot{
  background: #fff;
}

.dark_mode .react-calendar__tile--active .dm-total-mailers-sent-dot{
  background: #fff;
}

.react-calendar__tile--active .dm-total-mailers-to-send-dot{
  background: rgba(255, 255, 255, 0.75);
}

.dark_mode .react-calendar__tile--active .dm-total-mailers-to-send-dot{
  background: rgba(255, 255, 255, 0.75);
}

.react-calendar__tile--active .dm-total-mailers-in-queue-dot{
  background: rgba(255, 255, 255, 0.5);
}

.dark_mode .react-calendar__tile--active .dm-total-mailers-in-queue-dot{
  background: rgba(255, 255, 255, 0.5);
}


.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #eeeeee;
}
.dark_mode .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #192129;
}

.dropzone-button:hover{
  cursor: pointer;
}
