/* GENERAL */
body, html{
  color: #2C2C2C;
  background: #ffffff;
  height: 100%;
  margin: 0px;
  font-size: 18px;
  line-height: 36px;
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

body{
  overflow-x: hidden !important;
}
button{
  padding: 0px;
  margin: 0px;
}
@media (max-width: 767px) {
  body, html{
    overflow-x: hidden !important;
  }
}

header{
  width: 100%;
}

h1{
  font-size: 36px;
  line-height: 40px;

  font-family: "Raleway", sans-serif;
  color: #2C2C2C;
  margin: 0px 0px;

}

h2{
  font-size: 36px;
  line-height: 46px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin: 0px 0px;
  color: #2C2C2C;
}

/* NAVIGATION BAR STYLES */
.dm-website-navbar{

  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 20px 20px;
  background: transparent;

  -webkit-transition: background-color .5s ease-in-out;
  -moz-transition: background-color .5s ease-in-out;
  -o-transition: background-color .5s ease-in-out;
  transition: background-color .5s ease-in-out;

  backface-visibility: hidden;

}

.dm-website-navbar.dm-website-navbar-dark{
  background: transparent
}
.dm-website-navbar.dm-website-navbar-white{
  background: #fff !important;
  box-shadow: none !important;


}
.dm-website-navbar.dm-website-navbar-white.dm-website-navbar-scroll{
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1) !important;

}


.dm-website-navbar.dm-website-navbar-landing-page{
  position: absolute;
  padding: 20px 20px;
}
.dm-website-navbar.dm-website-navbar-scroll{

  background: #FFF !important;


  -webkit-transition: background-color .5s ease-in-out;
  -moz-transition: background-color .5s ease-in-out;
  -o-transition: background-color .5s ease-in-out;
  transition: background-color .5s ease-in-out;

  backface-visibility: hidden;
}

.dm-website-navbar.dm-website-navbar-landing-page.dm-website-navbar-scroll{
  background: inherit !important;

}

.dm-website-navbar.dm-website-navbar-landing-page .dm-website-toggle-button{
  display: none;
}


.dm-website-navbar-inner{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.shadow{
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1) !important;
}




.dm-website-navbar-logo{
  height: 17px;
}
.dm-website-partner-logos{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.dm-website-partner-logos .dm-website-navbar-logo{
  height: 13px;
  object-fit: contain;

}
.dm-website-partner-logos span{
  margin: 0px 10px 0px 5px;
  line-height: 17px;
}
.dm-website-navbar-white .dm-website-partner-logos span *,
.dm-website-navbar-dropdown-open .dm-website-partner-logos span *,
.dm-website-navbar-scroll .dm-website-partner-logos span *{
  color: #2C2C2C !important;
}
.dm-website-partner-logos .dm-website-navbar-partner-logo{
  height: 17px;
  object-fit: contain;
}

.dm-website-navbar-items{
  text-decoration: none;
  font-size: 16px;
  font-family: "Raleway", sans-serif;
}
.dm-website-navbar-items a{
  margin: 0px 15px;

}
.dm-website-navbar-items a:not(.dm-website-primary-cta){
  padding: 10px 0px;
  display: inline-block;
  color: #FFFFFF;
}


.dm-website-navbar.dm-website-navbar-white a:not(.dm-website-primary-cta){
  color: #31cce5;

}
.dm-website-navbar-items a:hover{
  color: #FFFFFF;
  text-decoration: underline;
}

.dm-website-toggle-button{
  display: none;
}
#dm-website-navbar-dropdown{
  display: none;
}


.dm-website-navbar-scroll .dm-website-navbar-items a:not(.dm-website-primary-cta){
  color: #31cce5;
}

.dm-website-navbar-spacing{
  flex: 1;
}

.dm-website-toggle-button{
  height: 28px;
  background: transparent;
  border: none;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  letter-spacing: 2.5px;  cursor: pointer;
  margin-right: 20px;
  color: #FFF;
  padding: 0;
  margin: 0;
}

.dm-website-navbar-scroll .dm-website-toggle-button{
  color: #31cce5;
}

.dm-website-hover-dropdown-button{
  display: inline-block;
  position: relative;
}

.dm-website-hover-dropdown{
  display: none;
  position: absolute;
  left: -60%;
  background: #fff;
  width: 300px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
  border: 1px solid #eee;

}

.dm-website-hover-dropdown:after, .dm-website-hover-dropdown:before {
  bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;

}

.dm-website-hover-dropdown:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #fff;
	border-width: 10px;
	margin-left: -10px;
}
.dm-website-hover-dropdown:before {
	border-color: rgba(238, 238, 238, 0);
	border-bottom-color: #eee;
	border-width: 11px;
	margin-left: -11px;
}

.dm-website-hover-dropdown-button:hover .dm-website-hover-dropdown,
.dm-website-hover-dropdown:hover{
  display: block;

}

.dm-website-hover-dropdown a{
  display: block;
  color: #31cce5 !important;
  padding: 0px;
  margin: 0px;
  width: 100%;
  line-height: 20px;

}
.dm-website-hover-dropdown a:first-child{
  border-radius: 5px 5px 0px 0px;
}
.dm-website-hover-dropdown a:last-child{
  border-radius: 0px 0px 5px 5px;
}
.dm-website-hover-dropdown a:hover{
  color: #31cce5 !important;
  background: #f7f7f7;
  text-decoration: none;
}

.dm-website-hover-dropdown .dm-website-dropdown-icon-button{

  color: #31cce5 !important;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.dm-website-hover-dropdown a img{
  width: 60px;
  height: 60px;

  object-fit: contain;
}
.dm-website-hover-dropdown a div{
  padding: 15px;

  flex: 1;
  min-height: 0px;

}


@media (max-width: 767px) {
  .dm-website-navbar-items{display: none;}
  .dm-website-navbar, .dm-website-navbar.dm-website-navbar-scroll{
    position: absolute !important;
    background: transparent !important;
    box-shadow: none !important;
    padding: 20px 10px;
  }

  .dm-website-navbar.dm-website-navbar-dropdown-open{
    background: #fff !important;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2) !important;

  }
  .dm-website-navbar-logo{
    height: 14px;
  }

  .dm-website-partner-logos .dm-website-navbar-logo{
    height: 10px;
  }
  .dm-website-partner-logos span{
    margin: 0px 5px 0px 5px;
  }
  .dm-website-partner-logos .dm-website-navbar-partner-logo{
    height: 14px;
  }

  .dm-website-navbar.dm-website-navbar-white{
    background: #fff !important;
  }

  .dm-website-toggle-button{
    display: block;
  }
  #dm-website-navbar-dropdown{
    display: block;
  }


  .dm-website-hover-dropdown{
    display: block;
    position: relative;
    left: inherit;
    background: transparent;
    width: auto;
    border-radius: 0px;
    box-shadow: none;
    border: none !important;
  }
  .dm-website-hover-dropdown:before, .dm-website-hover-dropdown:after{
    border: none !important;

  }
  .dm-website-hover-dropdown .dm-website-dropdown-icon-button{
    display: block;
  }
  .dm-website-hover-dropdown a div{
    font-weight: inherit;
    padding: 0px;
  }
  .dm-website-hover-dropdown a:hover{
    background: inherit !important;
    text-decoration: underline !important;
  }
  .dm-website-hover-dropdown-button{
    width: 100%;
  }
  .dm-website-hover-dropdown a img{
    display: none;

  }
  #dm-website-navbar-dropdown{
    margin-top: 20px;
  }

}


@media (min-width: 767px){
  .dm-website-toggle-button{display: none;}
}

.dm-website-toggle-button:focus{
  outline: none;
}

.dm-website-nav-dropdown{

  text-decoration: none;
  color: #31cce5;
  text-align: center;

}
.dm-website-nav-dropdown div{
  text-align: center;

}
.dm-website-nav-dropdown a:not(.dm-website-primary-cta){
  padding: 15px 0px;
  text-align: center;
}
/* .hidden{
  display: none;
}

.delay {
  -animation-delay: 1.6s;
  -webkit-animation-delay: 1.6s;
  -moz-animation-delay: 1.6;
} */

.animate_delay_025s{
  -animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25;
}
.animate_delay_05s{
  -animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5;
}
.animate_delay_1s{
  -animation-delay: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1;
}

.animate_delay_2s{
  -animation-delay: 2s;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2;
}

.animate_delay_3s{
  -animation-delay: 3s;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3;
}

.animate_delay_4s{
  -animation-delay: 4s;
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4;
}

.animate_delay_5s{
  -animation-delay: 5s;
  -webkit-animation-delay: 5s;
  -moz-animation-delay: 5;
}



.dm-website-nav-dropdown ul{
  margin: 0px;
  padding: 0px;
}
.dm-website-nav-dropdown ul a{
  display: block;
  text-align: center;
}


/* END NAVIGATION STYLES */

.dm-website-header-section h2{
  font-size: 22px;
  line-height: 32px;

}

h3{
  font-size: 20px;
  line-height: 32px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #2C2C2C;
}

h4{
  font-size: 22px;
  line-height: 28px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  color: #2C2C2C;
}

p{
  font-size: 18px;
  line-height: 36px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;

}

.success-steps p{
  font-size: 14px !important;
  line-height: 20px;

}

.dm-website-blog-content h2,
.dm-website-blog-content h3,
.dm-website-blog-content h4{
  font-weight: bold;
  margin: 40px 0px 40px;
}
.dm-website-blog-content p{
  margin: 0px 0px 20px;
}
.dm-website-blog-content img{
  text-align: center;
  width: 100%;
  max-height: 300px;
  object-fit: contain;
}

ul:not(div.deal-mailer-preview *){
  font-size: 18px;
  line-height: 36px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C;
  text-align: left;
}

@media(max-width: 767px){
  h1{
    font-size: 36px;
    line-height: 42px;
  }

  h2{
    font-size: 28px;
    line-height: 34px;
  }

  .dm-website-header-section h2{
    font-size: 22px;
    line-height: 28px;
  }

  h3{
    font-size: 22px;
    line-height: 28px;
  }

  h4{
    font-size: 18px;
    line-height: 26px;
  }

  p, ul{
    font-size: 16px;
    line-height: 26px;

  }

  body, html{
    font-size: 16px;
    line-height: 28px;
  }

}

b, strong{
  font-weight: bold;
}

a{
  color: #004F64;
  text-decoration: none;
}

a:hover{
  color: #004F64;
  text-decoration: underline;
}

/* custom sections */
#home-page-header .dm-website-section-inner{
  overflow: visible;
  padding: 10% 20px 5%;

}
#home-page-header .dm-website-section-container{
  max-width: 1100px;
}
#pricing-section .dm-website-section-container{
  max-width: 1000px;
}
#home-page-header h1{
  font-size: 36px;
  line-height: 40px;
}

#login-section .dm-website-section-inner, #sign-up-section .dm-website-section-inner{
  overflow: visible;
  padding: 10% 20px;
}

@media(max-width: 767px){
  #home-page-header .dm-website-section-inner{
    overflow: visible;
    padding: 100px 20px;
  }
  #login-section .dm-website-section-inner, #sign-up-section .dm-website-section-inner{
    overflow: visible;
    padding: 100px 20px;
  }
}


/*home page phone container */
.dm-website-home-phone-container{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.dm-website-home-phone-info{
  flex: 1;
  min-height: 0;
  text-align: left;
}

.dm-website-home-phone{
  max-width: 300px;
  width: 50%;
  position: relative;
}
.dm-website-home-phone img{
  width: 295px;
  position: absolute;
  left: 30px;
  top: 32px;
  z-index: 3;
  transform: rotate(-3deg);
}

.dm-website-home-phone video{
  z-index: 2;
  position: absolute;
  width: 236px;
  height: 415px;
  object-fit: cover;
  left: 57px;
  top: 97px;
  background: #eee;
  transform: rotate(-3deg);
}

@media(max-width: 767px){
  .dm-website-home-phone-container{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dm-website-home-phone-info{
    text-align: center;
  }
  .dm-website-home-phone{
    display: none;
  }
  .dm-website-home-phone img{
    position: relative;
    left: 0px;
    transform: rotate(0deg);

  }

}



.dm-website-overlay-video{

  max-width: 800px;
  margin: 0px auto !important;
  max-height: 500px;

}

.dm-website-overlay-video video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*home page video container */
.dm-website-home-video-container{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dm-website-home-video-info{
  flex: 1;
  min-height: 0;
  margin-top: 40px;
  padding-right: 20px;
  text-align: left;
}

.dm-website-home-video-wrapper{
  position: relative;
  width: 639px;
  height: 400px;
}


.dm-website-home-video{
  position: absolute;
  z-index: 1;
  top: 55px;
}
.dm-website-home-video video{
  object-fit: cover;



  width: 639px;
  height: 400px;

  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;


}


.dm-website-home-video-wrapper{
  position: relative;
  margin: 10px 0px;

}
.dm-website-home-video-wrapper:hover{
  margin: 0px 0px 20px;
}


.dm-website-home-video-overlay{
  position: absolute;

  z-index: 2;
  top: 55px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 100%;
  width: 100%;


  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  background-color: rgba(0,0,0,0);
  border-radius: 5px;

  -webkit-transition: background-color .25s ease-in-out;
  -moz-transition: background-color .25s ease-in-out;
  -o-transition: background-color .25s ease-in-out;
  transition: background-color .25s ease-in-out;

}
.dm-website-home-video-wrapper:hover .dm-website-home-video-overlay{
  cursor: hand;
  cursor:pointer;

  background-color: rgba(0,0,0,0.45);

}

.dm-website-home-video-playbutton{
  opacity: 0;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  font-weight: bold;
  color: #fff;
  font-family: "Raleway", sans-serif;

}

.dm-website-home-video-wrapper:hover .dm-website-home-video-overlay p,
.dm-website-home-video-wrapper:hover .dm-website-home-video-playbutton{
  opacity: 1;
  -webkit-transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -o-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
}


.dm-website-home-video-overlay p{
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  opacity: 0;

  -webkit-transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -o-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;

}


@media(max-width: 1000px){
  .dm-website-home-video-container{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dm-website-home-video-info{
    text-align: center;
    margin-top: 0px;
    padding-right: 0px;
  }



  .dm-website-home-video{
    width: 100%;
  }

  .dm-website-home-video{
    top: 0px;
    margin-top: 40px;
  }
  .dm-website-home-video-overlay{
    top: 0px;
    margin-top: 40px;

  }


}

@media(max-width: 767px){
  .dm-website-home-video-info{
    text-align: left;
  }
  .dm-website-home-video-overlay{
    height: 200px;
    top: 0px;
    margin: 40px 0px 0px;
  }
  .dm-website-home-video img{
    width: 320px;
    height: 200px;
  }

  .dm-website-home-video-wrapper{
    width: 300px;
    height: 200px;
  }
  .dm-website-home-video-overlay p{
    font-size: 14px;
    line-height: 18px;
  }

}


/* SECTIONS */

.dm-website-page{
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  position: relative;
}



.dm-website-section{
  background: #fff;
  position: relative;
  text-align: center;
}
.dm-website-section-inner{
  padding: 7% 20px 10%;
}
.dm-website-header-section .dm-website-section-inner{
  padding: 12% 20px 12%;
}
.dm-website-landing-page-section .dm-website-section-inner,
.dm-website-video-landing-page-section .dm-website-section-inner{
  padding: 2.5% 20px 5%;
}

@media(max-width: 767px){
  .dm-website-section-inner{
    padding: 60px 20px;
  }
  .dm-website-header-section .dm-website-section-inner{
    padding: 100px 20px;
  }



}

.dm-website-blog-post-header-section{
  height: 400px;
}
@media(max-width: 767px){
  .dm-website-blog-post-header-section{
    height: 200px;
  }
}
.dm-website-blog-feature-wrapper{
  height: 600px;
  position: relative;
}
.dm-website-section.dm-website-blog-list-feature-section{
  height: 600px;
  width: 100%;
  position: relative;
}
.dm-website-section.dm-website-blog-list-feature-section .dm-website-section-container{
  max-width: 750px;
  width: auto;
}
.dm-website-blog-feature-wrapper  .nav{
  display: none !important;
}
.dm-website-blog-feature-wrapper .indicators{
  bottom: 70px;
  z-index: 1;
  position: absolute;
  width: 100%;
}
.dm-website-blog-feature-wrapper .indicators div{
  background: rgba(255,255,255,0.5) !important;
}
.dm-website-blog-feature-wrapper .indicators div:hover{
  background: rgba(255,255,255,0.7) !important;
}
.dm-website-blog-feature-wrapper .indicators div.active{
  background: #fff !important;
}
.dm-website-section.dm-website-blog-list-feature-section .dm-website-section-inner{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  height: 600px;
  padding: 0px;
}
@media(max-width: 767px){
  .dm-website-blog-feature-wrapper{
    height: 450px;
  }
  .dm-website-section.dm-website-blog-list-feature-section{
    height: 450px;
  }
  .dm-website-section.dm-website-blog-list-feature-section .dm-website-section-inner{
    height: 450px;
  }
  .dm-website-blog-feature-wrapper .indicators{
    bottom: 50px;
  }
}


.dm-website-pagination{
  padding: 20px 0px;
  text-align: left;
}
.dm-website-pagination a{
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}
.dm-website-blog-post-section{
  text-align: left;
}
.dm-website-header-wrapper .dm-website-blog-post-section{
  padding: 12% 0px 7%;
}
.dm-website-blog-post-section .dm-website-section-container{
  max-width: 800px

}

.dm-website-blog-list-section .dm-website-section-container{
  max-width: 800px

}
.dm-website-section-container{
  max-width: 900px;
  margin: 0px auto;
}
.dm-website-white{
  background: #fff;
}
.dm-website-gray{
  background: #f7f7f7;
}
.dm-website-blue-gradient{
  background-image: linear-gradient(to right bottom, rgba(49,204,229, 1), rgba(30,212,225, 1), rgba(30,219,218, 1), rgba(51,226,207, 1), rgba(78,232,195, 1));
}
.dm-website-blue-gradient.dm-website-overlay{
  background-image: linear-gradient(to right bottom, rgba(49,204,229, 0.85), rgba(30,212,225, 0.85), rgba(30,219,218, 0.85), rgba(51,226,207, 0.85), rgba(78,232,195, 0.85));
}

.dm-website-dark-blue{
  background-image: linear-gradient(to left top, #31cce5, #1bbaea, #3ca6e9, #6390de, #8477c9);
}
.dm-website-dark-blue.dm-website-overlay{
  background-image: linear-gradient(to left top, #31cce5, #1bbaea, #3ca6e9, #6390de, #8477c9);
}

.dm-website-dark-gray{
  background: #2C2C2C;
  background-image: linear-gradient(to right bottom, #2C2C2C, #325459, #315d61, #306768, #30706e);
}

.dm-website-transparent{
  background: transparent;
}

.dm-website-classic-overlay{
  background-color: rgba(0,0,0,0.35);
  width: 100%;
  height: 100%;
}


.dm-website-dark-color h1,
.dm-website-dark-color h2,
.dm-website-dark-color h3,
.dm-website-dark-color h4,
.dm-website-dark-color p,
.dm-website-dark-color a,
.dm-website-dark-color ul{
  color: #fff;
}

.dm-website-diangle-bottom{
  position: absolute;
  pointer-events: none;
  bottom: -50px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: auto;

  transform:skew(0deg,-2deg);
  -ms-transform:skew(0deg,-2deg);
  -webkit-transform:skew(0deg,-2deg);
  background: #fff;
  height: 100px;

}
.dm-website-diangle-bottom img{
  width:100%;
  height: 100%;
  display: none;


}
.dm-website-diangle-top{
  position: absolute;
  pointer-events: none;
  top: -50px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: auto;

  transform:skew(0deg,-2deg);
  -ms-transform:skew(0deg,-2deg);
  -webkit-transform:skew(0deg,-2deg);
  background: #fff;
  height: 100px;
}
.dm-website-diangle-top img{
  width:100%;
  height: 100%;
  display: none;
}

@media (max-width: 767px) {
  .dm-website-diangle-top{
    top: -25px;
    height: 50px;

  }
  .dm-website-diangle-bottom{
    bottom: -25px;
    height: 50px;
  }

}

.dm-website-section-image{
  text-align: center;
  margin: 0px 0px 20px
}


.dm-website-section-image img{
  height: 250px;
  width: 250px;
  object-fit: contain;
}
.dm-website-help-popup{
  max-height: 600px;
  overflow: scroll !important;
}
.dm-website-help-image{
  text-align: center;
  margin: 0px 0px 20px
}

.dm-website-help-image img{
  height: 150px;
  object-fit: contain;
}

.dm-website-ctas{


}
.dm-website-ctas .dm-website-primary-cta:only-child{
  margin-right: 0px !important;
}
.dm-website-primary-cta, input[type="submit"],
.dm-website-navbar.dm-website-navbar-dark .dm-website-nav-dropdown .dm-website-primary-cta{
  font-weight: 400;
  color: #fff ;
  font-family: "Raleway", sans-serif;
  padding: 15px 30px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 10px;

  font-size: 16px;
  border-radius:5px;
  text-align: center;
  outline: none !important;
  text-decoration: none !important;
  display: inline-block;
  line-height: normal;

  border: 1px solid transparent;
  background: #31cce5;

  background: -moz-linear-gradient(left, #31cce5 0%, #4EE8C3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #31cce5 0%,#4EE8C3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #31cce5 0%,#4EE8C3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31cce5', endColorstr='#4EE8C3',GradientType=0 );
  background-image: linear-gradient(to right bottom, #31cce5, #4EE8C3);
}

.dm-website-primary-cta:hover,
.dm-website-primary-cta:active,
.dm-website-primary-cta:focus,
.dm-website-navbar.dm-website-navbar-dark .dm-website-nav-dropdown .dm-website-primary-cta:hover,
.dm-website-navbar.dm-website-navbar-dark .dm-website-nav-dropdown .dm-website-primary-cta:active,
.dm-website-navbar.dm-website-navbar-dark .dm-website-nav-dropdown .dm-website-primary-cta:focus{
  background: #1ddbd9;
  color:white;
  cursor: hand;
  cursor: pointer;
}

.dm-website-secondary-cta{
  font-weight: 400;
  color: #31cce5;
  font-family: "Raleway", sans-serif;
  padding: 15px 30px;
  margin-bottom: 5px;
  margin-top: 5px;

  font-size: 16px;
  border-radius:5px;
  text-align: center;
  border:none !important;
  outline: none !important;
  text-decoration: none !important;
  display: inline-block;
  line-height: normal;

  background: transparent;
  border: 1px solid #31cce5 !important;


}

.dm-website-secondary-cta:hover,
.dm-website-secondary-cta:active,
.dm-website-secondary-cta:focus{
  background: #1ddbd9;
  border: 1px solid #1ddbd9;

  color: #fff;
  cursor: hand;
  cursor: pointer;
}

.dm-website-dark-color .dm-website-primary-cta,
.dm-website-navbar-dark:not(.dm-website-navbar-scroll) .dm-website-primary-cta{
  background: #fff;

  background-image: linear-gradient(to right bottom, #ffffff, #fefdff, #fdfaff, #fef8ff, #fff5ff);

  color: #31cce5;
}
.dm-website-dark-color .dm-website-primary-cta:hover,
.dm-website-dark-color .dm-website-primary-cta:active,
.dm-website-dark-color .dm-website-primary-cta:focus,
.dm-website-navbar-dark:not(.dm-website-navbar-scroll) .dm-website-primary-cta:hover,
.dm-website-navbar-dark:not(.dm-website-navbar-scroll) .dm-website-primary-cta:focus,
.dm-website-navbar-dark:not(.dm-website-navbar-scroll) .dm-website-primary-cta:active{
  background: #fff5ff;
  color: #31cce5;
}

.dm-website-dark-color .dm-website-secondary-cta{
  border: 1px solid #fff !important;
  color: #fff;
}
.dm-website-dark-color .dm-website-secondary-cta:hover,
.dm-website-dark-color .dm-website-secondary-cta:active,
.dm-website-dark-color .dm-website-secondary-cta:focus{
  background: #fff5ff;
  border: 1px solid #fff5ff;
  color: #31cce5;
}

.dm-website-feature-cta{
  display: inline-block;
  color: #31cce5 !important;
  width: auto;
  text-decoration: none !important;

  font-size: 16px;

  -webkit-transition: color .25s ease-in-out;
  -moz-transition: color .25s ease-in-out;
  -o-transition: color .25s ease-in-out;
  transition: color .25s ease-in-out;
}
.dm-website-feature-cta div{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.dm-website-feature-cta div .material-icons{
  color: #31cce5 !important;
  margin-left: 5px;
  line-height: 20px;
}
.dm-website-feature-cta:hover,
.dm-website-feature-cta:active,
.dm-website-feature-cta:focus,
.dm-website-feature-cta:hover div .material-icons,
.dm-website-feature-cta:active div .material-icons,
.dm-website-feature-cta:focus div .material-icons{
  color: #1ddbd9 !important;
}

@media(max-width: 450px){
  .dm-website-primary-cta, .dm-website-secondary-cta{
    margin-right: 0px;
  }

  .dm-website-section-image img{
    height: 150px;
    width: 150px;
    object-fit: contain;
  }

  .dm-website-help-popup{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 90vh;
    border-radius: 0px;
    overflow-y: auto;
  }

}
.dm-website-features-icons{

  max-width: 1150px;
  margin: 0px auto;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dm-website-features-icons .dm-website-feature{

  margin: 60px 20px 40px;

  flex: 1;
  max-width: 350px;
  min-width: 260px;;
  min-height: 0;
}
.dm-website-features-icons.dm-website-features-4{
  max-width: 750px;

}
.dm-website-features-icons.dm-website-features-4 .dm-website-feature{
  max-width: 450px
}
.dm-website-features-icons.dm-website-features-4 .dm-website-feature p{
  font-size: 14px;
  line-height: 28px;
}
.dm-website-features-icons .dm-website-feature .dm-website-feature-image img{
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.dm-website-features-list{
  max-width: 800px;
  margin: 0px auto;
}

.dm-website-features-list .dm-website-feature{

  margin: 40px 0px;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;

}
.dm-website-features-list .dm-website-feature.dm-website-feature-custom{
  margin: 100px 0px 40px;
  overflow: hidden;
}

.dm-website-features-list .dm-website-feature .dm-website-feature-image{
  width: 200px;
  margin: 0px auto;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.dm-website-features-list .dm-website-feature:nth-child(even){
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.dm-website-features-list .dm-website-feature .dm-website-feature-image img{
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.dm-website-features-list .dm-website-feature .dm-website-feature-info{
  padding: 20px 40px;
  margin: 0px auto;
  flex: 1;
  min-height: 0;
  min-width: 0;
  text-align: left;
}

.dm-website-features-list .dm-website-feature:nth-child(even) .dm-website-feature-info{
  text-align: right;
}

.dm-website-testimonial-wrapper{
  max-width: 800px;
  margin: 40px auto 0px;
}
.dm-website-testimonial-image img{
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-bottom: 10px;
}

/* offers */

.dm-website-offer-top-content{
  max-width: 738px;
  margin: 0px auto 60px;

}

.dm-website-offer-video{
  width: 738px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.25);
  height: 415px;
  margin: -120px auto 20px;

  background: #fff;

  overflow: hidden;
}
.dm-website-offer-video iframe{
  border-radius: 5px;
  width: 738px;
  height: 415px;
}
.dm-website-offer-container{

  max-width: 950px;
  margin: 0px auto 10px;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

}


.dm-website-offer-content{
  flex: 1;
  min-height: 0;
  min-width: 0;

  text-align: left;
  padding: 0px 40px 0px 20px;
}

.dm-website-offer-form{
  max-width: 400px;
  flex: 1;
  min-height: 0;
  min-width: 0;
}

.dm-website-offer-container.dm-website-offer-video-container{
  margin-top: 40px;
}

.dm-website-card.dm-website-form-card{
  margin-top: -120px;
}

.dm-website-card.dm-website-form-card{
  margin-top: -180px;
}

.dm-website-offer-container.dm-website-offer-video-container .dm-website-card.dm-website-form-card,
.dm-website-offer-container.dm-website-offer-with-top-content-container .dm-website-card.dm-website-form-card{
  margin-top: 0px;
}




@media(max-width: 767px){

  .dm-website-offer-container{

    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;

  }
  .dm-website-offer-video{
    max-width: 400px;
    width: 100%;
    height: 225px;
    margin-top: -80px
  }
  .dm-website-offer-video iframe{
    max-width: 400px;
    width: 100%;

    height: 225px;
  }

  .dm-website-offer-content iframe{
    width: 100%;
    margin: 0px auto;
    max-width: 320px;
    height: 200px;
  }

  .dm-website-offer-content{
    padding: 20px;
    text-align: center;

  }

  .dm-website-offer-form{
    margin: 0px auto;
    width: 100%;
  }
  .dm-website-card.dm-website-form-card{
    margin-top: -50px;
  }

  .dm-website-offer-container.dm-website-offer-video-container .dm-website-card.dm-website-form-card{
    margin-top: 0px;
  }


  .dm-website-features-list .dm-website-feature{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dm-website-features-list .dm-website-feature:nth-child(even){
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dm-website-features-list .dm-website-feature .dm-website-custom-feature-container{
    width: 100%;
  }
  .dm-website-features-list .dm-website-feature:nth-child(even) .dm-website-feature-info{
    text-align: center;
  }

  .dm-website-features-list .dm-website-feature .dm-website-feature-info{
    text-align: center;
  }

}

.dm-website-card{
  background: #fff;
  margin: 7px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;

  align-self: stretch;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.dm-website-card-body{
  padding: 20px;
}


.dm-website-offer-page-video{
 margin: 0px auto 40px;
 text-align: center;
}
.dm-website-offer-page-video iframe{
  height: 240px;
  width: 408px;
  margin: 0px auto;
}


.dm-website-section-container iframe:not(.instagram-media .video-wrapper){
  height: 380px;
  width: 700px;

  text-align: center;
  padding: 0px 0px 20px;
  margin: 0px auto;
  display: block;

}


.dm-website-section-container iframe.instagram-media .video-wrapper{
  margin: 0px auto 12px !important
}

@media(max-width: 767px){
  .dm-website-offer-page-video iframe:not(.instagram-media .video-wrapper){
    height: 180px;
    width: 320px;
  }

  .dm-website-section-container iframe:not(.instagram-media .video-wrapper){
    height: 180px;
    width: 320px;
  }

}
a.dm-website-card-link{
  text-decoration: none !important;

}




.dm-website-stats{

  max-width: 1100px;
  margin: 0px auto;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dm-website-stats .dm-website-stat{

  margin: 40px 20px;

  flex: 1;
  max-width: 350px;
  min-width: 260px;;
  min-height: 0;
}
.dm-website-stats.dm-website-stats-4{
  max-width: 750px;

}

.dm-website-stats .dm-website-stat .dm-website-stat-number{

  font-family: "Raleway", sans-serif;

  text-align: center;
  font-size: 80px;
  line-height: 100px;
  font-weight: bold;
  color: rgba(49,204,229, 1);

}

.dm-website-stats-container h2{
  font-size: 48px;
line-height: 58px;
}

.dm-website-stats-container h2 span{
  color: rgba(49,204,229, 1);

  font-size: 56px;
}
@media(max-width: 767px){

  .dm-website-stats .dm-website-stat .dm-website-stat-number{
    font-size: 60px;
    line-height: 80px;
  }


  .dm-website-stats-container h2{
    font-size: 36px;
    line-height: 48px;
  }
  .dm-website-stats-container h2 span{
  font-size: 48px;
  }
}


.dm-website-cards{

  max-width: 1100px;
  margin: 0px auto;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dm-website-cards .dm-website-card{

  margin: 10px 20px;

  flex: 1;
  max-width: 350px;
  min-width: 260px;;
  min-height: 0;

  text-align: left;

  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

.dm-website-cards .dm-website-card:hover{
  margin: 0px 20px 20px;

}
.dm-website-card-image{
  height: 200px;
}
.dm-website-card-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dm-website-cards.dm-website-cards-4{
  max-width: 750px;

}

.dm-website-footer-icon{
  text-align: center;
}
.dm-website-footer-icon img{
  width: 80px;
  object-fit: contain;
  margin-bottom: 40px
}

#dm-website-landing-page-footer-section .dm-website-footer-icon img{
  margin-bottom: 0px

}

#dm-website-landing-page-footer-section a{
  color: #2C2C2C;
}

.dm-website-footer-wrapper, .dm-website-footer-info{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  text-align: left;
}
.dm-website-footer-info{
  flex: 3;
  min-height: 0;

}
.dm-website-footer-nav{
  flex: 1;
  min-height: 0;
  padding: 20px;
  padding-top: 0px;
}
.dm-website-footer-info ul{
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.dm-website-footer-info ul li{
  line-height: 24px;
  margin: 0px 0px 20px;
}
.dm-website-footer-info ul li a:hover{
  cursor: hand;
  cursor: pointer;
}
.dm-website-footer-form{
  padding: 20px;
  padding-top: 0px;
  text-align: left;

  flex: 2;
  min-height: 0;
}
.dm-website-footer-form h3{
  font-size: 18px;
  margin: 0px;
  margin-bottom: 20px;
  font-weight: bold;
}
.dm-website-footer-bottom-text p{
  text-align: center;
  font-size: 14px;
  margin-top: 40px;
}

@media(max-width: 767px){
  .dm-website-footer-wrapper, .dm-website-footer-info{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.dm-website-blog-author{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;


  text-align: left;
  padding: 60px 0px;
}

.dm-website-blog-author-image{
  width: 150px;
  text-align: center;
  padding: 0px 40px 0px 0px;
}
.dm-website-blog-author-image img{
  border-radius: 50%;
  object-fit: cover;
  width: 150px;
  height: 150px;
}
.dm-website-blog-author-info{
  flex: 1;
  min-height: 0;
  min-width: 0;
}
@media(max-width: 767px){
  .dm-website-blog-author{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dm-website-blog-author-image{
    width: 100%;

  }
  .dm-website-blog-author-info{
    text-align: center;
  }
}

.dm-website-form-section{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.dm-website-form-section-content{
  flex: 1;
  min-height: 0;
  min-width: 0;

  text-align: left;
  padding: 0px 40px 0px 20px;
}

.dm-website-form-wrapper{
  max-width: 400px;
  flex: 1;
  min-height: 0;
  min-width: 0;
}

@media(max-width: 767px){
  .dm-website-form-section{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dm-website-form-wrapper{
    margin: 0px auto;
  }
}

.dm-website-pricing-wrapper{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  margin: 0px 0px 100px;
}


.dm-website-pricing-block{

  background: #fff;
  margin: 0px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;

  align-self: stretch;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  flex: 1;
  min-height: 0;
  min-width: 0;

  max-width: 639px;
  margin: 0px 10px;
  width: 100%;
}



.dm-website-pricing-block-header{
  padding: 40px 40px 80px;
  background: #f7f7f7;

  overflow: hidden;
  position: relative;

}
.dm-website-pricing-featured .dm-website-pricing-block-header{
  background: #2C2C2C;
  background-image: linear-gradient(to right bottom, #2C2C2C, #325459, #315d61, #306768, #30706e);

  background-image: linear-gradient(to right bottom, rgba(49,204,229, 1), rgba(30,212,225, 1), rgba(30,219,218, 1), rgba(51,226,207, 1), rgba(78,232,195, 1));
  color: #fff !important;
}
.dm-website-pricing-featured .dm-website-pricing-block-header .dm-website-pricing-block-price{
  color: #fff !important;
  background: transparent;
}

.dm-website-pricing-block-title{
  font-size: 26px;
  line-height: 26px;
}
.dm-website-pricing-block-price{
  font-size: 62px;
  line-height: 72px;
  font-weight: bold;
  color: rgba(49,204,229, 1);

}
.dm-website-pricing-block-subprice{
  font-size: 18px;
  line-height: 18px;
}
.dm-website-pricing-block-body{
  padding: 20px 40px;

  background: #fff;
  font-size: 16px;
  line-height: 26px;

  text-align: left;
}

.dm-website-pricing-item, .dm-website-pricing-item p{
  font-size: 18px;
  line-height: 26px;
  margin: 0px;
}

li.dm-website-pricing-item{
  margin: 30px 0px;
}
.dm-website-pricing-item-price-text{
  font-size: 14px;
  margin: 0px;
  font-weight: bold;
}
.dm-website-pricing-item-help-text{
  font-size: 14px;
  margin: 0px;
}

@media(max-width: 767px){
  .dm-website-pricing-block-header{
    padding: 40px 40px 60px;
  }
  .dm-website-pricing-wrapper{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dm-website-pricing-block, .dm-website-pricing-featured{
    margin: 20px auto;
  }
}

.dm-website-signup-section{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dm-website-signup-content{
  flex:1;
  min-height: 0;
  min-width: 0;
  padding: 40px;
  text-align: left;

  font-size: 14px;

}
.dm-website-signup-content h1{
  font-size: 26px;
  line-height: 32px;
  font-weight: bold;
  text-align: left;
}
.dm-website-signup-content h2{
  font-size: 22px;
  line-height: 28px;

  text-align: left;
}

.dm-website-signup-content p, .dm-website-signup-content ul{
  font-size: 14px;
  line-height: 28px;
  text-align: left;
}
.dm-website-signup-image{
  text-align: center;

}
.dm-website-signup-image img{
  height: 150px;
  object-fit: contain;
}
.dm-website-signup-form{
  flex:1;
  min-height: 0;
  min-width: 0;
  padding: 40px;

  border-left: 1px solid #f7f7f7;


  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.dm-website-terms-text{
  padding: 20px 20px 0px;
}
.dm-website-signup h3{
  display: none;
}
@media(max-width: 767px){
  .dm-website-signup-section{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dm-website-signup-content{
    padding: 40px
  }
  .dm-website-login .dm-website-signup-content{
    display: none;
  }



  .dm-website-signup h1{
    text-align: center;
  }

  .dm-website-signup-form{
    padding: 0px;
  }
  .dm-website-terms-text{
    padding: 20px;
  }
}

.dm-website-terms-text p{
  text-align: left;
  font-size: 12px;
  line-height: 24px;
}

.dm-website-custom-feature-container{
  width: 320px;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dm-website-custom-feature-container .dm-input textarea{
  resize: none !important;
}

.dm-website-particles-wrapper{
  position: relative;
  height: 550px;
  overflow: hidden;

}
.dm-website-particles{
  height: 550px !important;
  overflow: hidden;
  background-color:#2C2C2C;

}
.dm-website-particles-info{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom:0;
  z-index: 0;
  height: 550px;
  width: 100%;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dm-website-particles-info .dm-website-overlay{

  width: 100%;
  height: 100%;

}

@media (max-width: 767px) {
  .dm-website-particles-info,
  .dm-website-particles,
  .dm-website-particles-wrapper{
    height: 450px;
  }
}

.dm-website-sample-search-inputs{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.dm-website-sample-search-inputs .deal-card{
  flex: 1;
  overflow: visible !important;
}
.dm-website-sample-search-inputs input{
  overflow: visible !important;

}
.dm-website-samples-used{
  font-size: 14px;
}

.dm-website-owner-info-container{
  max-width: 800px;
  margin: 0px auto;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;

  width: 100%;
}

.dm-website-owner-info{
  flex: 1;
  min-height: 0;

  text-align: left;


}

.dm-website-owner-more-info{
  flex: 1;
  min-height: 0;

  width: 100%;
}

@media (max-width: 767px) {
  .dm-website-owner-info-container{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dm-website-sample-search-inputs{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dm-website-sample-search-inputs .deal-card{

    overflow: visible !important;

  }
}

.dm-website-direct-mail-header-container{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

}

.dm-website-direct-mail-header-info{
  flex: 1;
  min-height: 0;
  text-align: left;

}

.dm-website-direct-mail-header-postcard{
  max-width: 475px;
  width: 50%;
  position: relative;
}

@media (max-width: 767px) {
  .dm-website-direct-mail-header-postcard{
    width: 100%;
    margin: 40px auto 20px;
  }
  .dm-website-direct-mail-header-info{
    text-align: center;
  }
  .dm-website-direct-mail-header-container{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.dm-website-loading{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  background: #f7f7f7;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  opacity: 0.4;

  display: none;

}


.dm-website-loading-image{
  width: 150px;
  height: 150px;
}
.dm-website-loading-image img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dm-website-testimonial-video-wrapper{
  position: relative;
  height: 650px;
  overflow: hidden;
}
.dm-website-testimonial-video-wrapper video{
  height: 650px;
  position: absolute;
  top: 0;
  bottom:0;
  right: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}

.dm-website-testimonial-video-inner{
  height: 650px;
  position: absolute;
  top: 0;
  bottom:0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1;

}

.dm-website-testimonial-video-inner .dm-website-overlay{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .dm-website-testimonial-video-wrapper{
    height: auto;
  }
  .dm-website-testimonial-video-wrapper video{
    display: none;
  }

  .dm-website-testimonial-video-wrapper{
    height: auto;
  }

  .dm-website-testimonial-video-inner{
    height: auto;
    position: relative;
  }
  .dm-website-testimonial-video-inner .dm-website-overlay{
    height: auto;
    width: auto;
    padding: 60px 20px;
  }
}


.dm-website-logos{
  padding: 25px 0px 0px;
  opacity: 0.8;

}
.dm-website-logos.dm-website-logos-centered{
  text-align: center;
  margin: 0px auto;
  width: 310px;
}
.dm-website-logos img{
  object-fit: contain;
  width: 60px;
  margin-right: 15px;
  display: block;
  float: left;
}
.dm-website-logos img:first-child{
  margin-top: 2px;

}
.dm-website-logos img:nth-child(3){
  width: 100px;
  margin-top: 4px;
  margin-right: 6px;
}
.dm-website-logos img:last-child{
  width: 50px;
  margin-top: -5px;
  margin-right: 0px;
}

.dm-website-home-phones{
  max-width: 450px;
  width: 50%;
  overflow: visible;
  pointer-events: none;
}


.dm-website-home-phones-container{
  position: relative;
  z-index: 1;

  transform: rotate(25deg);
  margin-left: 100px;
  margin-top: 50px;

}

.dm-website-phone{

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 240px;
  height: 420px;
  border: 2px solid #f7f7f7;

  background: #fff5ff;
  background-image: linear-gradient(to right bottom, #ffffff, #fefdff, #fdfaff, #fef8ff, #fff5ff);

  box-shadow: 5px 5px 9px rgba(0,0,0,0.25);

  border-radius: 20px;
  padding: 40px 10px;

  /*
  -ms-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  */

}

.dm-website-phone-inner{
  background: #f7f7f7;
  border: 2px solid #eee;
  height: 420px;

  overflow: hidden !important;

  position: relative;

   -moz-box-shadow:    inset 0 0 10px rgba(0,0,0,0.05);
   -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
   box-shadow:         inset 0 0 10px rgba(0,0,0,0.05);

}

.dm-website-letter{
  position: absolute;
  z-index: 1;
  top: -100px;
  left: 300px;

  width: 450px;
  height: 300px;

  background: #fff;

  box-shadow: 5px 5px 9px rgba(0,0,0,0.25);

  border-radius: 5px;
  overflow: hidden;

}



.dm-website-letter-inner{
  position: relative;
}

.dm-website-letter-stamp1{
  position: absolute;
  width: 100px;
  height: 100px;

  top: 30px;
  left: 20px;


}
.dm-website-letter-stamp1 img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dm-website-letter-stamp2{
  position: absolute;

  position: absolute;
  width: 200px;
  height: 125px;

  top: 20px;
  left: 80px;

}
.dm-website-letter-stamp2 img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.dm-website-letter-image{
  width: 250px;
  height: 300px;
  float: left;
}
.dm-website-letter-image img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.dm-website-letter-text{
  width: 200px;
  height: 300px;
  float: left;
  background: #fff;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

}

.dm-website-ipad{
  position: absolute;
  border: 2px solid #f7f7f7;
  z-index: 1;
  top: 240px;
  left: 300px;

  width: 600px;
  height: 400px;

  background: #fff5ff;
  background-image: linear-gradient(to right bottom, #ffffff, #fefdff, #fdfaff, #fef8ff, #fff5ff);

  box-shadow: 5px 5px 9px rgba(0,0,0,0.25);

  border-radius: 20px;
  padding: 40px 10px;
}

.dm-website-ipad-inner{
  background: #f7f7f7;
  border: 2px solid #eee;
  height: 400px;

  position: relative;

  background: url(https://images.ctfassets.net/tbaxdqcpgx77/4k8BXoyjBScQaMuKeug0I2/5972c03512a37a5a88aba57929e1024b/home-background.svg);
  background-size: 110%;
  background-position: center center;
}

.dm-page-phone-header-info{
  width: 500px;
  text-align: left;
}
.dm-page-phone-header-info p{
  font-size: 26px;
}
#home-page-phone-header .dm-website-section-inner{
  padding: 12% 20px 12%;

}
#home-page-phone-header-2 .dm-website-section-inner{
  padding: 15% 20px 15%;
}


.dm-website-phone-app-image{
  width: 100%;
  height: 150px;
}
.dm-website-phone-app-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dm-website-phone-app-container{

}

.dm-website-phone-app-container .deal-card:first-child{

margin-top: -25px;
}

.dm-website-phone-app-container .deal-primary-button{
  font-size: 18px;
}

.dm-website-gray-box-copy{
  background: #f7f7f7;
  height: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.dm-website-gray-box-letter-copy:first-child{
  margin-top: 100px;
}
.dm-website-gray-box-letter-copy{
  background: #f7f7f7;
  height: 5px;
  width: 150px;
  margin-bottom: 5px;
}

.dm-website-letter-stamp3{
  background: #f7f7f7;
  width: 60px;
  height: 70px;
  top: 10px;
  right: 10px;
  position: absolute;

}

@media(min-width: 1100px){
  #home-page-phone-header-2 .dm-website-section-inner{
    padding: 8% 20px 15%;
  }
}

@media(max-width: 1000px){
  #home-page-phone-header-2 .dm-website-section-inner{
    padding: 20% 20px 15%;
  }
}


@media(max-width: 850px){
  .dm-website-home-phone-container{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .dm-website-home-phone-info{
    text-align: center;
  }

  .dm-website-home-phones{
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform: scale(0.7);

    z-index: 1;
    max-width: 100%;
    width: 100%;
    margin-top: 180px;
  }
  .dm-website-home-phones-container{
    transform: rotate(25deg);
    margin: 0px;
    margin-left: 100px;
  }

  #home-page-phone-header .dm-website-section-inner{
    padding: 140px 20px 40px;
  }

  #home-page-phone-header-2 .dm-website-section-inner{
    padding: 640px 20px 40px;
  }
  .dm-page-phone-header-info{
    text-align: center;
    width: 100%;
  }
  .dm-page-phone-header-info p{
    font-size: 18px;
  }

  .dm-website-logos{
    text-align: center;
    margin: 0px auto;
    width: 310px;
  }


}


@media(max-width: 767px){

  .dm-website-home-phones{
    -ms-transform: scale(0.65);
    -moz-transform: scale(0.65);
    -o-transform: scale(0.65);
    -webkit-transform: scale(0.65);
    transform: scale(0.65);

    z-index: 1;
    max-width: 100%;
    width: 100%;
    margin-top: 140px;
  }
  .dm-website-home-phones-container{
    margin: 0px;
    margin-left: 50px
  }

  #home-page-phone-header .dm-website-section-inner{
    padding: 140px 20px 40px;
  }

  #home-page-phone-header-2 .dm-website-section-inner{
    padding: 600px 20px 40px;
  }



}


@media(max-width: 475px){

  .dm-website-home-phones{
    -ms-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform: scale(0.5);

    z-index: 1;
    max-width: 100%;
    width: 100%;
    margin-top: 80px;
  }
  .dm-website-home-phones-container{
    margin: 0px;
    margin-left: -100px
  }

  #home-page-phone-header .dm-website-section-inner{
    padding: 100px 20px 40px;
  }

  #home-page-phone-header-2 .dm-website-section-inner{
    padding: 440px 20px 40px;
  }
}

.dm-website-brand-swatch-container{
  display: flex;
  flex-direction: column;
  height: 175px;
  width: 120px;
  background: #FFF;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
  border-radius: 5px;
  margin: 2.5px;
}

.dm-website-brand-swatch{
  display: flex;
  width: 100%;
  height: 100px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}


.dm-website-share-button-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
  margin: 20px auto 0px;
}

.dm-website-share-button-container .svg{
  outline: none;
}

audio {
    color: #95B9C7;
}

.dm-website-article-card{
  min-height: 50px;
  padding: 10px;
  text-align: left;
  margin: 5px 0 5px 0;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;

  align-items: center;
  align-self: stretch;
  transition-timing-function: ease-in-out;
  transition: all .3s;
  border-bottom: 1px solid #31CCE5;
}

.dm-website-article-card:hover{
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
  border-radius: 5px;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  border-bottom: none;
}

.dm-website-article a:hover{
  text-decoration: none;
}
.dm-website-article{
  min-width: 100%;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.dm-website-audiobutton{
  background-color: #FFF;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.dm-website-audio-menu{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 0px;
  overflow-y: hidden;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.dm-website-audio-menu-open{
  background-color: #EEE;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 300px;
  overflow-y: scroll;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
.dm-website-audio-menu-open::-webkit-scrollbar {
    width: 5px;  /* remove scrollbar space */
    background: #31CCE5;  /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
.dm-website-audio-menu-open::-webkit-scrollbar-thumb {
    background: #EAEAEA;
}

#audio-card{
  cursor: pointer;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
  display: flex;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animate: 0.2s;
  background: #31CCE5;
  border-radius: 1.3px;
  /* border: 0.2px solid #010101; */
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #31CCE5;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #31CCE5;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animate: 0.2s;
  background: #31CCE5;
  border-radius: 1.3px;
  /* border: 0.2px solid #010101; */
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #31CCE5;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

/* Date Picker Styles */

.DayPicker *{
  font-size: 14px !important;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C;
}
.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #31CCE5;
  outline: none;
}
.DayPicker-Day {
  border-radius: 0 !important;
  font-size:14px;
  padding:0px;
  outline: none;

}
.DayPicker-Day--start:not(.DayPicker-Day--outside) {
  background-color: #31CCE5 !important;
  border-radius: 5px !important;
  outline: none;
}
.DayPicker-Day--end:not(.DayPicker-Day--outside) {
  background-color: #31CCE5 !important;
  border-radius: 5px !important;
  outline: none;
}
.DayPicker-Day--today{
  color: #004E64;
  outline: none;
}
.DayPicker-Day--disabled {
  outline: none;
}

.DayPicker-NavButton{
  outline: none;
}

.DayPicker-wrapper{
  outline: none;
}

.engine-tabs{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.engine-tab-button{
  padding: 5px 20px;
  background: transparent;
  border-radius: 40px;
  margin: 0px 5px;
  font-weight: bold;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}
.engine-tab-button:hover{
  cursor: hand;
  cursor: pointer;
}
.engine-tab-button.active{
  color: white;
  background: -moz-linear-gradient(left, #31cce5 0%, #4EE8C3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #31cce5 0%,#4EE8C3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #31cce5 0%,#4EE8C3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31cce5', endColorstr='#4EE8C3',GradientType=0 );
  background-image: linear-gradient(to right bottom, #31cce5, #4EE8C3);

}

.pricing-feature{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;
}

.pricing-helper-text{
  font-size: 12px;
  line-height: 12px;
}

.pricing-extra-text{
  color: white;
  background: -moz-linear-gradient(left, #31cce5 0%, #4EE8C3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #31cce5 0%,#4EE8C3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #31cce5 0%,#4EE8C3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31cce5', endColorstr='#4EE8C3',GradientType=0 );
  background-image: linear-gradient(to right bottom, #31cce5, #4EE8C3);

  padding: 5px 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;

  margin: 5px;
  margin-left: 10px;
  border-radius: 30px;

}

.engine-tab-extra{
  color: white;
  background: -moz-linear-gradient(left, #31cce5 0%, #4EE8C3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #31cce5 0%,#4EE8C3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #31cce5 0%,#4EE8C3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31cce5', endColorstr='#4EE8C3',GradientType=0 );
  background-image: linear-gradient(to right bottom, #31cce5, #4EE8C3);

  padding: 5px 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;

  margin: 0px;
  margin-left: 10px;
  border-radius: 30px;
}
.engine-tab-button.active .engine-tab-extra{
  display: none;
}

@media (max-width: 767px) {
  .engine-tabs{
    overflow-x: hidden !important;
    flex-direction: column;
  }
  .engine-tab-button{
    margin: 10px 5px;
  }
  .engine-tab-button .engine-tab-extra{
    display: none;
  }
}
#deal-sign-up-sidebar{
  width: 40%;
  max-width: 300px;
  align-self: stretch;
  background:transparent;
  /* background: rgba(49,204,229, 1); */
  /* background-image: linear-gradient(to right bottom, rgba(49,204,229, 1), rgba(30,212,225, 1), rgba(30,219,218, 1), rgba(51,226,207, 1), rgba(78,232,195, 1)); */
  align-self: column;
  flex-direction: column;
  align-self: stretch;
  display: -ms-flexbox;
  display: -webkit-flex;
  z-index: 0;
  /* box-shadow: 1px 1px 5px rgba(0,0,0,0.1); */
  /*border-right: 1px solid #2C2C2C;*/
  display: flex;
  overflow: auto;
  padding: 80px 40px;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.signup-wrapper{
  background-image: linear-gradient(to right bottom, rgba(49,204,229, 1), rgba(30,212,225, 1), rgba(30,219,218, 1), rgba(51,226,207, 1), rgba(78,232,195, 1));
  height:100vh;
}

#deal-signup-container{
  flex: 1;
  align-self: stretch;
  overflow: auto;
  background-image: linear-gradient(to right bottom, rgba(49,204,229, 1), rgba(30,212,225, 1), rgba(30,219,218, 1), rgba(51,226,207, 1), rgba(78,232,195, 1));
}

#deal-login-container{
  flex: 1;
  align-self: stretch;
  overflow: auto;
  background-image: linear-gradient(to right bottom, rgba(49,204,229, 1), rgba(30,212,225, 1), rgba(30,219,218, 1), rgba(51,226,207, 1), rgba(78,232,195, 1));
}
#deal-signup-inner{
  max-width: 750px;
  margin: 25px auto;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1) !important;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 767px) {
  #deal-signup-container{
    background-image: none;
  }
  #deal-signup-inner{
    margin: 0px auto;
    border-radius: 0px;
  }
  #deal-login-container{
    height:100vh;
  }
}
#deal-sign-up-sidebar-2{
  width: 40%;
  max-width: 300px;
  align-self: stretch;
  align-self: column;
  flex-direction: column;
  align-self: stretch;
  display: -ms-flexbox;
  display: -webkit-flex;
  z-index: 0;
  display: flex;
  overflow: auto;
  padding: 80px 40px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-left: 1px solid #eee;
}
@media (max-width: 1200px) {
  #deal-signup-container{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    padding: 0px;
    height: inherit;
  }

  #deal-sign-up-sidebar, #deal-sign-up-sidebar-2{
    display: none;
  }


}

@media (max-width: 767px) {
  #deal-signup-container{
    background-image: none;
  }

  #deal-signup-inner{
    box-shadow: none !important;
  }


}
