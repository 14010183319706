
#app, #app>div{
  height: 100%;
  overflow: none;
}
[data-reactroot]{height: 100% !important;}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   opacity: 0;
}
iframe{
  border: none !important;
  margin: none !important;
  margin-bottom: -5px !important;
}
form{
  margin-bottom: 20px;
}

hr{
  border-top: 1px solid #eee;
}
.status_icon{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}
.status_icon i{
  font-size: 14px;
  margin-right: 5px;
}
.double-input{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}
.host_area{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 15px 15px;;
  border-right: 1px solid #eee;
  align-self: stretch;
  font-size:18px;
}
.double-input.team_link .dm-input{
  flex: 1;
  border-bottom: none;
}


.dm-affiliate-logos{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom:1px solid #eee;
}

.dm-affiliate-logo{
  flex: 1;
  height: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dm-affiliate-logo img{
  width:90%;
  height: 50px;
  object-fit: contain;
}
.get-started-partner-logos{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width:400px;
}
.get-started-partner-plus{
  height: 50px;
  width:80px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
}

.get-started-partner-logo{

  height: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.get-started-partner-logo img{
  width:170px;
  height: 50px;
  object-fit: contain;
}


.dm-get-started-wrapper{
  margin: 0px auto 0px;
  height:100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.dm-get-started-right{
  flex: 1;
  width:"100%";
  max-width:500px;
  background: "#fff";

}
.dm-get-started-left{
  flex: 1;
  height:100%;

}
.video-container{
  position: relative;
  width: 100%;
  height:100%;
}
.video-wrapper{
  width:100%;
  height:100%;
  position: absolute;
  overflow: hidden;
}
.video-inner{
  width:100%;
  height:100%;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  background-color: rgba(	49, 204, 229, 0.9);
}
.video-inner-container{
  padding: 5%;
}
.video-h1{
  font-size:48px;
  color:white;
  line-height: 60px;
  font-weight: 200;

}
.video-h2{
  font-size: 20px;
  line-height: 26px;
  color:#fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.video-buttons{
  position: absolute;
  width:100%;
  height:100px;
  bottom: 0px;
  right:0;
  left:0;
  background:rgba(0,0,0,.35);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.video-button{
  color:white;
  background: transparent;
  border: none;
  display: block;
  height:100px;
  width:150px;
  margin: 0px 20px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  border-bottom: 5px solid transparent;
  justify-content: center;
  align-items: center;
}

.video-button:hover,
.video-button:focus{
  border-bottom: 5px solid rgba(255, 255, 255, 0.25);
  outline: none !important;
}
.video-button.active{
  border-bottom: 5px solid #fff !important;
}
.dm-background-video{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.dm-card{
  background: #fff;
  align-self: stretch;
  width:100%;
  overflow: hidden;
}
.feature{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}
.feature i{
  font-size: 32px;
  color: #31cce5;
  margin-right: 25px;
}
.feature-text{
  flex: 1;

}
.feature-text h4{
  font-size: 20px;
  line-height: normal;
  margin: 0px;
  padding: 0px;
}
.feature-text p{
  font-size: 14px;
  line-height: normal;
  margin: 0px;
  padding: 0px;
}

.dm-title{
  font-weight: 200;
  font-size: 20px;
  color: #2C2C2C;
  font-family: "Raleway", sans-serif;
}
.dm-margin{
  margin: 15px 0px;
}
.dm-image{
  flex: 1
}
.dm-preview-wrapper .dm-image:first-child{
  margin-right: 5px;
}
.dm-preview-wrapper .dm-image:last-child{
  margin-left: 5px;
}
.dm-image img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.dm-preview-wrapper{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
}
.center{
  text-align: center;
}
.uppercase{
  text-transform: uppercase;
}
.small-container{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
}
.medium-container{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  width: 100%;
  max-width: 600px;
  min-width: 400px;
  margin: 0px auto;
  padding: 20px;
}
.medium-container .medium-container{
  padding: 0px;
}
.large-container .medium-container{
  padding: 0px;
}
.flex-fill{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  width: 100%;

}
.large-container{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  width: 100%;
  max-width: 800px;
  min-width: 600px;
  margin: 0px auto;
  padding: 20px;
}
.flex-item{
  flex-direction: column;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-fill{
  flex: 1;min-height:0;
  align-items: stretch;
  flex-direction: column;
}
.center-flex{
  flex: 1;min-height:0;
  align-items: center;
  justify-content: center;
}
.left-flex{
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
}
.icon-button{
  font-size: 26px;
  line-height: 26px;
  margin-right: 5px;
}
.dm-navbar .icon-button{
  color: #fff !important;
}
.dm-navbar .icon-button:hover{
  color: #f7f7f7 !important;
}
.map-card{
  height: 300px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.map-card-overlay{
  position: absolute;
  top: 0;
  left:0;
  background:rgba(255,255,255,0.6);
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-card-overlay .map-marker{
  width: 40px;
  height: 40px;
  margin-bottom: 25px;
}
.map-card-overlay .map-marker img{
  width: 100%;
  height: 100%;
  object-fit: contain;

}
.card{
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
  background: #fff;
  margin: 7px 0px;
  align-self: stretch;

  border-radius: 5px;
  overflow: hidden;
}

.card-body{
  padding: 25px;
  border-bottom: 1px solid #eee;
}



.card-label{
  font-size: 14px;
  min-height:0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C;
  line-height: 20px;
  flex-direction: row;
  align-items: center;
  display:flex;
  justify-content: flex-start;
}
.card-label i{
  font-size: 20px;
  line-height: 20px;
  margin-right: 5px;
}
.half-card-body{
  flex: 1;min-height:0;
}
.half-card-body:first-child{
  border-right: 1px solid #eee
}
html, body{
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C;
  height: 100%;
  margin: 0px;
  font-size: 14px;
}

h1{
  font-size: 32px;
  font-family: "Raleway", sans-serif;
  font-weight: 200;

  color: #2C2C2C;
  margin: 20px 0px;
}




h2{
  font-weight: 200;
  font-size: 26px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C;
  margin: 20px 0px;
}


h3{
  font-weight: 400;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C;
  margin: 20px 0px;
}


h4{
  font-weight: 200;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C;
  margin: 20px auto;
}


h5{
  font-weight: 200;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C;
  margin: 0px;
}


h6{
  font-weight: 200;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C;
  margin: 0px;
}



.white-text{
  color: #fff;
}

b, strong{
  font-weight: bold;
}

a{
  color: #31cce5;
  text-decoration: none;
}

a:hover{
  color: #31cce5;
  text-decoration: underline;
}
a.white-text{
  color: #fff;
}
a.white-text:hover{
  color: #fff;

}
.dark-blue-color{
  color:hsl(193, 100%, 20%);
}
.fill-background{
  height: 100%;

}
.gradient-background{
  background: rgba(49,205,229,1);
  background: -moz-linear-gradient(top, rgba(49,205,229,1) 0%, rgba(22,184,209,1) 62%, rgba(15,166,189,1) 100%);
  /*background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(49,205,229,1)), color-stop(62%, rgba(22,184,209,1)), color-stop(100%, rgba(15,166,189,1)));*/
  background: -webkit-linear-gradient(top, rgba(49,205,229,1) 0%, rgba(22,184,209,1) 62%, rgba(15,166,189,1) 100%);
  background: -o-linear-gradient(top, rgba(49,205,229,1) 0%, rgba(22,184,209,1) 62%, rgba(15,166,189,1) 100%);
  background: -ms-linear-gradient(top, rgba(49,205,229,1) 0%, rgba(22,184,209,1) 62%, rgba(15,166,189,1) 100%);
  background: linear-gradient(to bottom, rgba(49,205,229,1) 0%, rgba(22,184,209,1) 62%, rgba(15,166,189,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31cde5', endColorstr='#0fa6bd', GradientType=0 );
}

.get-started-top-container{
  flex: 4;
  align-items: center;
  justify-content: flex-end;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
}
.login-top-container{
  flex: 1;min-height:0;
  align-items: center;
  justify-content: flex-end;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
}

.get-started-bottom-container{
  flex: 4;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
}
.feature-image{
  height: 250px;
  margin: 5px 0px;
  width: 100%;
  object-fit: contain;
}
.get-started-info-container{
  background: "#eee";
  padding: 25px;
  min-height: 100%;
  margin-bottom: 60px;
}
.tab-container{
  max-width:550px;
  margin: 0px auto;
}
.scroll-container{
  flex: 1;
  min-height:0;
  align-self: stretch;
  overflow: hidden;
  overflow-y: auto;
  text-align: center;
}

.scroll-me{
  flex: 1;min-height:0;
  align-self: stretch;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height:100% !important;
}

.get-started-icon-image{
  height: 125px;
  width: 125px;
  object-fit: contain;
}

.get-started-logo-image{
  height: 40px;
  margin-top: 15px;
  margin-bottom: 0px;
  max-width: 250px;
  object-fit: contain;
}
button{
  border-radius: 0px;
}
.primary-button{
  font-weight: 200;
  color: #fff !important;
  font-family: "Raleway", sans-serif;
  background: #31cce5;
  padding: 20px;;
  font-size: 20px;
  border-radius:5px;
  text-align: center;
  border:none !important;
  align-self: stretch;
  outline: none !important;
  text-decoration: none !important;
  align-items: center;
  display: block;
  line-height: normal;

  background: #31cce5; /* Old browsers */
  background: -moz-linear-gradient(left, #31cce5 0%, #4EE8C3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #31cce5 0%,#4EE8C3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #31cce5 0%,#4EE8C3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31cce5', endColorstr='#4EE8C3',GradientType=0 );

}
.primary-button:hover,
.primary-button:active,
.primary-button:focus{
  background: #1bb0c8;
  cursor: hand;
  cursor: pointer;
}
.secondary-button{
  font-weight: 200;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(left, #ffffff 0%, #f7f7f7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ffffff 0%,#f7f7f7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ffffff 0%,#f7f7f7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f7f7',GradientType=0 );
  border-radius:5px;
  color: #31cce5 !important;
  font-family: "Raleway", sans-serif;
  background: #fff;
  padding: 20px;;
  font-size: 20px;
  text-align: center;
  border:none !important;
  align-self: stretch;
  outline: none !important;
  text-decoration: none !important;
  align-items: center;
  line-height: normal;
}

.secondary-button:hover,
.secondary-button:active,
.secondary-button:focus{
  background: #eee;
  cursor: hand;
  cursor: pointer;
}
.destroy-button{
  font-weight: 200;
    border-radius:0px;
  color: #fff !important;
  font-family: "Raleway", sans-serif;

  background: #B24C63; /* Old browsers */
  background: -moz-linear-gradient(left, #B24C63 0%, #a42f49 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #B24C63 0%,#a42f49 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #B24C63 0%,#a42f49 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#B24C63', endColorstr='#a42f49',GradientType=0 );

  padding: 20px;;
  font-size: 20px;
  text-align: center;
  border:none !important;
  align-self: stretch;
  outline: none !important;
  text-decoration: none !important;
  align-items: center;
  line-height: normal;
}


.destroy-button:hover,
.destroy-button:active,
.destroy-button:focus{
  background: #a42f49;
  cursor: hand;
  cursor: pointer;
}

.create-button{
  font-weight: 200;
  color: #fff !important;
  font-family: "Raleway", sans-serif;
  background: #28F29E; /* Old browsers */
  background: -moz-linear-gradient(left, #28F29E 0%, #1de692 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #28F29E 0%,#1de692 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #28F29E 0%,#1de692 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28F29E', endColorstr='#1de692',GradientType=0 );

  padding: 20px;;
  font-size: 20px;
  text-align: center;
  border:none !important;
  align-self: stretch;
  outline: none !important;
  text-decoration: none !important;
  align-items: center;
}


.create-button:hover,
.create-button:active,
.create-button:focus{
  background: #1de692;
  cursor: hand;
  cursor: pointer;
}
.block-button{
  display: inline-block;
  margin-top: 10px;
}
.small-padding{
  padding: 20px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  color: #2C2C2C;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.dm-new-input{
  text-align: left;
  position: relative;
  line-height: 18px;
  flex: 1;
  min-width: 50px;
  align-self: stretch;
  display: flex;
  align-items:  center;
  overflow: visible;

}
.dm-new-input .floating-label{
  position: absolute;
  color: #b1b1b1;
  top: -7px;
  display: none;
  -webkit-animation-duration: .25s;
  -moz-animation-duration: .25s;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 0;
}
.dm-new-input .floating-label label{
  line-height: 0px;
}
.dm-new-input .floating-label.active{
  display:block;
}
.standard-label{
  color: #b1b1b1;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}
.dm-new-input .standard-label{
  color: #b1b1b1;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}

.dm-new-input .standard-label label{
  line-height: 10px;
}


.dm-new-input input,
.dm-new-input textarea{
  width: 100%;
  background: transparent;
  border: none;
  outline: none !important;
  padding: 0px;
  color: #2C2C2C;
  align-self: stretch;
  font-size: 14px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
}

.dm-new-input textarea{
  height: auto;
  width: 100%;
  align-self: stretch;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  resize: vertical;

}


.dm-new-input ::-webkit-input-placeholder{
  color: #b1b1b1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

}
.dm-new-input ::-moz-input-placeholder{
  color: #b1b1b1;
}
.dm-input{
  text-align: left;
  padding: 0px 15px;
  position: relative;
  flex: 1;

}
.dm-input.no_container{
  padding: 0px
}
.dm-input.icon{
  padding: 0px;
}
.dm-input .floating-label{
  position: absolute;
  color: #b1b1b1;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  display: none;
  -webkit-animation-duration: .25s;
  -moz-animation-duration: .25s;

}
.dm-input.small .floating-label{
  font-size: 10px;
  margin-top: 5px;

}
.standard-label{
  display:flex;
  justify-content: space-between;
}

.standard-label label{
  color: #b1b1b1;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
}

.dm-new-input{
  text-align: left;
  position: relative;
  line-height: 18px;
  flex: 1;
  min-width: 100px;
}
.dm-new-input .floating-label{
  position: absolute;
  color: #b1b1b1;
  top: -7px;
  display: none;
  -webkit-animation-duration: .25s;
  -moz-animation-duration: .25s;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}
.dm-new-input .floating-label label{
  line-height: 0px;
}
.dm-new-input .floating-label.active{
  display:block;
}

.dm-new-input .standard-label{
  color: #b1b1b1;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}

.dm-new-input .standard-label label{
  line-height: 10px;
}


.dm-new-input input,
.dm-new-input textarea{
  width: 100%;
  background: transparent;
  border: none;
  outline: none !important;
  padding: 0px;
  color: #2C2C2C;
  align-self: stretch;
  font-size: 14px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;

}

.dm-new-input textarea{
  height: auto;
  width: 100%;
  align-self: stretch;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
}


.dm-new-input ::-webkit-input-placeholder{
  color: #b1b1b1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

}
.dm-new-input ::-moz-input-placeholder{
  color: #b1b1b1;
}

.dm-input .floating-label label{
  font-weight: 400;
}
.dm-input .floating-label.active{
  display:block;
}
.dm-input .standard-label label{
  font-weight: 400;
}
.dm-input.small .floating-label.active{
  margin-top: 3px;
  line-height: 0px;
  white-space: nowrap;

}

.dm-input input,
.dm-input textarea{
  width: 90%;
  background: transparent;
  height: 69px;
  border: none;
  font-size: 20px;
  outline: none !important;
  padding: 0px;
  color: #2C2C2C;
  align-self: stretch;
  min-height: 45px;
  max-height: 180px;
}
.dm-input textarea{
  resize: vertical;
  height: 70px;
  width: 100%;
  align-self: stretch;

  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;

}

.dm-input textarea::-webkit-input-placeholder{
  font-size: 14px;
}
.dm-input.small{
  padding: 0px;
}
.dm-input.small input{
  width: 90%;
  background: transparent;
  height: 18px;
  border: none;
  font-size: 14px;
  outline: none !important;
  padding: 0px;
  color: #2C2C2C;
  align-self: stretch;
  min-height: 18px;
  max-height: 18px;
}

.dm-input.select{
  padding: 6px;

}
.dm-input.select .floating-label{
  margin-top: 2px;
}
.dm-input.select select{
  width: 100%;
  height: 59px;
      background: transparent;
  border: none;
  font-size: 20px;
  outline: none !important;
  padding: 0px 15px;;
  color: #2C2C2C;
  align-self: stretch;
  -webkit-appearance: menulist-button;
}
.dm-input.select.bold-select select{
  color: #2C2C2C;
}
.dm-input.select:hover,
.dm-input.select:hover select{
  cursor: hand;
  cursor: pointer;
  background:#f7f7f7;
}
.dm-input.select select:not(.active){
  color: #b1b1b1;
}
.dm-input.select select.active{
  height: 49px;
  margin-top: 10px;
}
.dm-input.select label{
  padding: 0px 9px;
}
.dm-input ::-webkit-input-placeholder{
  color: #b1b1b1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
}
.dm-input ::-moz-input-placeholder{
  color: #b1b1b1;
}


.dm-input.small ::-webkit-input-placeholder{
  font-size: 14px;
}
.dm-input.small input.active{
  height: 20px;
  margin-top: 5px;
}
.dm-input input.active{
  height: 59px;
  margin-top: 10px;
}
.dm-spinner-overlay{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.deal-full-app .dark-spinner-overlay{
  background: rgba(0,0,0, 0.5);
}
.dm-spinner-text{
  font-weight: 200;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C;
  text-align: center;
  max-width:500px;
  margin: 0px auto 20px;
}
.dm-small-spinner-overlay{
  width: 100%;
  align-self: stretch;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 20px 0px;
  align-items: flex-start;
  justify-content: center;
}
.dm-small-spinner-overlay.nopadding{
  margin: 0px;
}

.dm-bottom-left-alert{
  position: fixed;
  right: 0;
  z-index: 3;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  bottom: 0px;
  font-size: 20px;
  color: white;
  pointer-events: none;
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
}

.dm-error-overlay{
  position: fixed;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 60px;
  bottom: 0px;
  background: #B24C63;
  font-size: 20px;
  color: white;
  pointer-events: none;
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
}
.dm-message-background{
  position: fixed;
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.75);
}
.dm-message-overlay{
  position: fixed;
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  pointer-events: none;
  width: 100%;
  bottom: 0px;
  right:0px;
  padding: 20px;
  max-width: 400px;

  flex-direction: column;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.dm-message{
  box-shadow: 1px 1px 5px rgba(0,0,0,0.25);
  background: #fff;
  margin: 5px 0px;
  align-self: stretch;
  border-radius: 5px;
  overflow: hidden;
  max-width: 400px;
  padding: 20px;;
  flex: 1;

  flex-direction: row;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;

}
.dm-message-icon{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px

}
.dm-message-icon i{
  font-size: 42px;

  margin-right:
}
.dm-message-info{

  flex-direction: column;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.dm-message-title{
  font-weight: 200;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C !important;
}
.dm-message-text{
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C  !important;
  font-size: 14px;
}
.dm-success-overlay{
  position: fixed;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 60px;
  bottom: 0px;
  background: #28F29E;
  font-size: 20px;
  color: white;
  pointer-events: none;
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
}

/* APP */

.dm-searchbar{
  position: absolute;
  flex-direction: row;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background: #f7f7f7;
  height: 60px;
  min-width: 400px;
  width: 100%;
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  -webkit-animation-duration: .25s;
  -moz-animation-duration: .25s;
}

.dm-navbar{
  position: relative;
  flex-direction: row;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
  background: #31cce5; /* Old browsers */
  background: -moz-linear-gradient(left, #31cce5 0%, #1bb0c8 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #31cce5 0%,#1bb0c8 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #31cce5 0%,#1bb0c8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31cce5', endColorstr='#1bb0c8',GradientType=0 );

  height: 60px;
  min-width: 400px;
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
}
.dm-navbar-title{
  flex: 1;
  min-height:0;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.dm-navbar-title-text{
  flex: 1;
  font-weight: 200;
  font-size: 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
}

.dm-navbar-subtitle{
  flex: 1;
  font-weight: 200;
  font-size: 14px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  flex: 1;
}
.dm-navbar-button-wrapper{
  width: 60px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;;
}

.dm-navbar-button-wrapper.dm-navbar-button-wrapper-text{
  width: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 20px;;
}
.dm-navbar-button-wrapper.dm-navbar-button-wrapper-text .dm-navbar-button{
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  font-weight: 200;
}
.dm-navbar-button{
  text-decoration: none !important;
  color: white;
  font-size: 26px;
  line-height: 30px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

}
.dm-searchbar .dm-navbar-button-wrapper .dm-navbar-button .icon-button{
  color: #2C2C2C !important;
}
.dm-searchbar .dm-navbar-button-wrapper .dm-navbar-button .icon-button:hove{
  color: #2C2C2C !important;
}
.dm-navbar-button:hover{
  text-decoration: none !important;
  cursor: hand;
  cursor:pointer;
  color: #f7f7f7;
}
.dm-navbar-button.dm-logo{
  height: 25px;
}
.dm-navbar-button.dm-logo img{
  height: 25px;
  object-fit: contain;
}
.dm-searchbar .dm-search{
  flex: 1;min-height:0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px;

}
.dm-searchbar .dm-search input{
  border: none;
  background: transparent;
  color: #2C2C2C;
  font-size: 20px;
  padding: 0px;
  width: 100%;
  margin: 0px;
  outline: none !important;
}
.dm-searchbar .dm-search input:focus{

}
.dm-app{
  flex: 1;min-height:0;
  flex-direction: column;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-self: stretch;
  background: #eee;
}

.dm-sidebar{


  width: 75px;
  background: #2C2C2C;
  background-image: linear-gradient(to right bottom, #2C2C2C, #325459, #315d61, #306768, #30706e);
  align-self: column;
  flex-direction: column;
  align-self: stretch;
  display: -ms-flexbox;
  display: -webkit-flex;
  z-index: 0;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
  /*border-right: 1px solid #2C2C2C;*/
  display: flex;
  overflow: auto;
  max-width: 75px;

}




.dm-sidebar.open, .dm-sidebar:hover{
  display: flex;
  max-width: 300px;
  flex: 1;
  transition: max-width 0.25s;

}

.dm-sidebar.open .single-logo, .dm-sidebar:hover .single-logo{
  display: none;
}
.dm-sidebar .double-logo{
  display: none;
}
.dm-sidebar.open .double-logo, .dm-sidebar:hover .double-logo{
  display: flex;
}


.dm-sidebar-hidden-button-section{
  opacity: 0;
  width: 0px;
  width: 0px;

}

.dm-sidebar-hidden-profile{
  opacity: 0;
  flex: 0;
  width: 0;
  height: 0;
  width: 0px;


}

.dm-sidebar:hover .dm-sidebar-hidden-button-section,
.dm-sidebar.open .dm-sidebar-hidden-button-section{
  opacity: 1;
  width: inherit;
  height: inherit;
  width: 225px;
  transition: opacity 0.5s, width 0.5s;
  white-space: nowrap;

}

.dm-sidebar:hover .dm-sidebar-hidden-profile,
.dm-sidebar.open .dm-sidebar-hidden-profile{
  opacity: 1;
  width: inherit;
  height: inherit;
  width: 225px;
  transition: opacity 0.5s, width 0.5s;
  white-space: nowrap;
}


@media(max-width: 767px){

  .dm-sidebar, .dm-sidebar.open{
    width: 0px;
    position: fixed;
    top:0;
    left: 0;
    z-index: 1;
    display: none;
    background: #2C2C2C;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

  }

  .dm-sidebar.open{
    width: 300px;
    height: 100%;
    display: block;
  }

}

.deal-full-app .dm-sidebar-dark{
  border-right: 1px solid #424242;
  background-color: rgb(36, 37, 38);

}


/*
.dm-sidebar.deal-sidebar-mobile{
  width: 0px;
  position: fixed;
  top:0;
  left: 0;
  z-index: 1;
  display: none;
  background: white;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

}

.dm-sidebar.deal-sidebar-mobile.open{
  width: 300px;
  height: 100%;
  display: block;

}
*/

.dm-sidebar-inner{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1;min-height:0;
  flex-direction: column;

}
.dm-mainview{
  flex: 1;min-height:0;
  flex-direction: column;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-self: stretch;
  background: #eee;
}
.dm-mainview-inner{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.dm-sidebar-top{
  max-height: 300px;
  height: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 20px;

}
.dm-sidebar-menu{
  flex: 1;min-height:0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.dm-profile{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-self: stretch;
}
.dm-profile-image{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.dm-profile-image img{
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}
.dm-profile-info{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.dm-profile-header{
  font-weight: 200;
  font-size: 20px;
  color: #2C2C2C;
  font-family: "Raleway", sans-serif;
}
.dm-profile-subheader{
  font-size: 14px;
}

.dm-stats{
  align-self: stretch;
  width: 100%;
  margin: 0px 0px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
}
.dm-stat{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 10px;
  flex: 1;min-height:0;
  text-decoration: none !important;
}
.dm-stat-number{
  font-size: 36px;
  color: #31cce5;
  margin-bottom: 10px;
  line-height: 36px;
  font-weight: 200;
  font-family: "Raleway", sans-serif;
}
.dm-stat-label{
  font-size: 14px;
  color: #2C2C2C;

}

.dm-progress-wrapper{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0px 20px;
  text-align: center;
  align-self: stretch;
}
.dm-progress{
  float: none;
  border-radius: 0px;
  width: 100%;
  box-shadow: none;
  overflow: visible;
  background: #eee !important;
  height: 7px;
  margin-bottom: 7px;
}
.dm-progress-bar{
  height: 7px;
  background:#31cce5;
}

.dm-sidebar-menu-item{
  text-decoration: none !important;
  height: 60px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  -webkit-transition: background-color .25s ease-in-out;
  -moz-transition: background-color .25s ease-in-out;
  -o-transition: background-color .25s ease-in-out;
  transition: background-color .25s ease-in-out;
}
.dm-sidebar-menu-item.active{
  background: #eee;
  -webkit-transition: background-color .25s ease-in-out;
  -moz-transition: background-color .25s ease-in-out;
  -o-transition: background-color .25s ease-in-out;
  transition: background-color .25s ease-in-out;
}
.dm-sidebar-menu-item:not(.active):hover{
  background: #f7f7f7;
  cursor: pointer;
  cursor: hand;
  color: #2C2C2C;
}
.dm-sidebar-menu-icon{
  font-size: 26px;
  flex: 2;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: #2C2C2C;
  justify-content: center;
}

.dm-sidebar-menu-label{
  font-weight: 200;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C;
  flex: 8;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dm-deals{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  number: 2;
}
.dm-deal{
  width: 50%;
  text-decoration: none !important;
  color: #2C2C2C;
}
.white-background{
  background: #fff;
}

.dm-deal:hover{
  color: #2C2C2C;
}
.dm-deal:last-child{
  margin-bottom: 20px;
}
.dm-deal:hover .card{
}
.dm-deal-inner{
  padding: 0px 5px 0px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.dm-deal-header{
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 200px;
  width: 100%;
}
.dm-deal-header.noimage{
  height: auto;
}
.dm-deal-header.large{
  height: 300px;
  align-self: stretch;
}
.dm-deal-header img{
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  image-orientation: from-image;
}
.dm-deal-location i{
  font-size: 14px;
}
.dm-deal-location{

  width: 100%;
  color: white;
  font-size: 14px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dm-deal-location.nooverlay{
  color: #2C2C2C;
}
.dm-deal-overlay{
  background: rgba(0,0,0,0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dm-deal-overlay-inner{
  padding: 20px;;
  flex: 1;
  align-self: stretch;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.dm-deal-status{
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C;
  font-size: 14px;
}
.dm-owner{
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C;
  font-size: 14px;
}
.dm-owner-name{
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  color: #2C2C2C;
  font-size: 20px;
}
.dm-card-menu-item{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  text-decoration: none !important;
}
.dm-card-menu-item.link:hover{
  background: #f7f7f7;
}

.dm-card-bottom{
  border-top: 1px solid #f7f7f7;
  background: #fff;
}

.dm-testimonial{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:10px;
  text-align: center;
  margin-bottom: 40px;
}
.dm-testimonial-image{
  width:70px;
  height:70px;
  margin-bottom: 20px;

}
.dm-testimonial-image img{
  border-radius: 50%;
  width:100%;
  height:100%;
  object-fit: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dm-testimonial-info{
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dm-testimonial-name{
  margin: 0px 0px 0px;
}
.dm-card-menu-info{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 12;
}
.dm-card-menu-title{
  font-weight: 200;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C !important;
}
.dm-card-menu-subtitle{
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C  !important;
  font-size: 14px;
}
.dm-card-menu-icon{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;min-height:0;
  font-size: 26px;
  color: #2C2C2C;
  -webkit-animation-duration: .25s;
  -moz-animation-duration: .25s;

}

.alert-modal-overlay{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.6);
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
}
.dm-testimonial-stars{
  margin: 15px 0px;
  color: hsl(193, 100%, 20%) !important;
}
.alert-modal{

  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
  background: #fff;
  margin: 10px;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
}
.alert-modal-inner{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: stretch;
  text-align: center;
}
.alert-modal-icon{
  color: #31cce5;
  font-size: 32px;
}
.alert-modal-icon .icon-button{
  font-size: 32px;

}
.alert-modal-icon img{
  max-width: 300px;
  object-fit: contain;
  margin-bottom:20px;
}
.alert-modal-title{
  font-weight: 200;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C !important;
}
.alert-modal-price{
  font-weight: 200;
  font-size: 36px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C !important;
  margin: 15px 0px
}
.large-price{
  font-weight: 200;
  font-size: 36px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C !important;
  margin: 15px 0px;
  text-align: center;
}
.large-price span{
  font-size: 20px;
}
.alert-modal-price span{
  font-size: 20px;
}
.alert-modal-description{
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C  !important;
  font-size: 14px;
  margin: 15px 0px;
}
.alert-modal-code{
  font-weight: 200;
  font-size: 22px;
  font-family: "Raleway", sans-serif;
  color: #2C2C2C !important;
  margin: 15px 0px;
}
.alert-modal-cancel-wrapper{
  margin-top: 15px;
  font-size: 14px;
}

.animated-container{
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
}

.dm-toggle-switch{
  border: 1px solid #31cce5;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  font-size: 14px;
  margin-bottom: 15px;
}

.dm-toggle-switch-item{
  flex: 1;min-height:0;
  text-decoration: none !important;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #31cce5;
  background: #fff;
  padding: 10px;
  border: none;
  box-shadow: none;
  text-shadow: none;
  outline: none !important;
}
.dm-toggle-switch-item:hover{
  background: #f7f7f7;
}
.dm-toggle-switch-item.active,
.dm-toggle-switch-item.active:hover{
  background: #31cce5;
  color: #fff;
}
.dm-plan-feature{
  flex-direction: row;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.dm-dropdown{
  position: relative;
  display: inline-block;
}
.dm-dropdown-button{

}
.dm-dropdown-content{
  display: none;
    position: fixed;
    right: 20px;
    background-color: #fff;
    min-width: 200px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
    z-index: 1;
}
.dm-dropdown-content.left-content{
  left: 0px;
  position: absolute;
  right: inherit;
}
.dm-dropdown-content a {
    color: black;
    padding: 20px 15px;
    text-decoration: none;
    display: block;
}
.dm-dropdown-content a.destroy{
  color: #B24C63;
}
.dm-dropdown-content a:hover {
  background-color: #f7f7f7
}
.dm-dropdown-content a.active{
  color: #2C2C2C;
  font-weight: bold;
}
.dm-dropdown:hover .dm-dropdown-content {
    display: block;
}
.dm-dropdown:hover .dm-dropdown-button {
    color: #f7f7f7;
}
.dm-dropdown .dm-dropdown-button i {
  margin-left: 5px;
}
.dm-deal-filter-bar{
  font-size: 14px;
  width: 100%;
  background: #fff;
}

.dm-deal-filter-bar .dm-dropdown-button{
  color: #2C2C2C;
  text-decoration: none;
  padding: 10px 20px;
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dm-deal-filter-bar:hover .dm-dropdown-button{
  color: #2C2C2C !important;
  background: #f7f7f7;
}

.dm-loadmore{
  width: 100%;
  font-weight: 200;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  margin-bottom: 00px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dm-loadmore a{
  padding: 20px 20px;
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important

}

.dm-loadmore a:hover{
  color: #1bb0c8;
}
.dm-tabs-container{
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
  background: #fff;
  margin: 10px 0px;
  align-self: stretch;
  overflow: hidden;
}
.dm-tabs{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;min-height:0;
}

.dm-tabs button.tab{
  flex: 1;min-height:0;
  border: none;
  border-bottom: 1px solid #ddd;
  padding: 20px;;
  background: transparent;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  outline: none !important;
}
.dm-tabs button.tab:hover{
  background: #f7f7f7
}
.dm-tabs button.tab.active{
  flex: 2;
  border: none;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.dm-tabs button.tab.active:first-child{
  flex: 2;
  border: none;
  border-right: 1px solid #ddd;
}
.dm-tabs button.tab.active:last-child{
  flex: 2;
  border: none;
  border-left: 1px solid #ddd;
}
.tab-button-inner{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.tab-button-inner span{
  margin-left: 5px;
}
.dm-info-item{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;;
  flex: 1;min-height:0;
}
.dm-info-item-double{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}


.dm-center-padding{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;;
  flex: 1;min-height:0;
}
.dm-split-buttons{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.dm-split-buttons button{
  flex: 1;min-height:0;
  padding: 10px;
  background:transparent;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  border-top: 1px solid #ddd;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  outline: none !important;

}
.dm-split-buttons button:hover{
  background: #f7f7f7;
}
.dm-split-buttons button:first-child{
  border-right: 1px solid #ddd;
}
.dm-team-header{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: transparent;
  font-weight: 200;
  font-size: 20px;
  color: #2C2C2C;
  font-family: "Raleway", sans-serif;
  border:none;
  padding: 20px 0px 0px;
  outline: none !important;
}
.dm-team-subheader{
  font-weight: 400;
  font-size: 14px;
  color: #2C2C2C;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.dm-team-header:hover{
  color: #2C2C2C;
}
.dm-team-member-wrapper{
  display: block;
  text-decoration: none !important;

}
.dm-team-member-wrapper:hover{
  background: #f7f7f7;
}
.dm-team-member{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dm-enhanced-search{
  border-top: 1px solid #eee;
}

.address-item{
  padding: 10px 0px;
  border-bottom: 1px solid #eee;
}
.address-item:last-child{
  padding: 10px 0px 0px;
  border-bottom: none;
}

.slider-testimonial{
  text-align: center
}
.slider-testimonial-image{
border-radius: 50%;
width: 120px;
height:120px;
margin: 10px auto
}
.slider-testimonial-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.slider-testimonial-info{

}

.slider-testimonial-title{
  font-weight: 200;
  font-size: 20px;
  color: #2C2C2C;
  font-family: "Raleway", sans-serif;
}
.slider-testimonial-subtitle{
  font-weight: 400;
  font-size: 14px;
  color: #2C2C2C;
  font-family: "OpenSans", sans-serif;
}
.animated{
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
}

.plan-row{
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  align-self: stretch;
}

.plan-row .plan-item{
  width: 33%;
  align-self: stretch;
}
.plan-row .plan-item .deal-card{
  align-self: stretch;
  flex: 1
}
@media(max-width: 767px){
  .plan-row{
    flex-direction: column;
  }
  .plan-row .plan-item{
    width: 100%;

  }
}

.dm-dashboard-columns{
  flex-direction: row !important;
  align-items: flex-start;
  justify-content: flex-start;

  align-self: stretch;
}
@media(max-width: 767px){
  .dm-dashboard-columns{
    flex-direction: column;
  }
}
.dm-info-banner{
  background-color: #4EE8C3;
  color: #fff;
  padding: 0px 15px;
  font-size: 12px;
}

.dm-info-banner.error{
  background-color: #B24C63;
}

video.video-cover{
  object-fit: cover;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide_scroll_bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide_scroll_bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

pre {
    border-radius: 3px;
    font-family: courier, monospace;
    padding: 0 5px;
    font-size: 14px;
    line-height: 18px;
}
.responsive-list-main-column{

}
.responsive-list-main-column:hover{
  background: #f7f7f7 !important;
}
.dark_mode .responsive-list-main-column:hover{
  background: #1F2933 !important;
}
.responsive-list-main-column .responsive-list-main-column-button{
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left:0;
}

.responsive-list-main-column:hover .responsive-list-main-column-button{
    opacity: 1;
}

.striped-error-border{
  border-image: repeating-linear-gradient(
    45deg,
    rgba(178,76,99, 0.75),
    rgba(178,76,99, 0.75) 10px,
    rgba(255,156,176, 0.75) 10px,
    rgba(255,156,176, 0.75) 20px) 20;
}

.striped-error-background{
  background: repeating-linear-gradient(
    45deg,
    rgba(178,76,99, 0.75),
    rgba(178,76,99, 0.75) 10px,
    rgba(255,156,176, 0.75) 10px,
    rgba(255,156,176, 0.75) 20px
  );
}
.grid-background{
  background-size: 20px 20px;
  background-image:
    linear-gradient(to right, #eee 1px, transparent 1px),
    linear-gradient(to bottom, #eee 1px, transparent 1px);
}

.dm-input-box:hover{
  cursor: text !important;
}

.dm-fade-out-text-overlay {
  top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(to right, rgba(255,255,255,0) 75%,rgba(247,247,247,1) 100%);
   position: absolute;
}

.dm-fade-out-text-overlay.active {
  top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(to right, rgba(255,255,255,0) 75%,rgba(255,255,255,1) 100%);
   position: absolute;
}

.MuiTooltip-tooltip{
  background: rgba(0,79,100,0.75), !important;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif !important;

}

.mapboxgl-popup-close-button {
  font-size: 22px;
  width: 36px;
  height: 36px;
  border-radius: 13px;
  margin: 5px;
  color: #2C2C2C;

}
.mapboxgl-popup-content {

  font-size: 14px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2C2C2C;

  padding: 0;
  width: 300px;
  border-radius: 5px;
}

.mapboxgl-popup-content h3 {

}

.mapboxgl-popup-content div {
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {

}




#login-container{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  -webkit-animation-duration: .25s;
  -moz-animation-duration: .25s;
  overflow-x: hidden;
  height: 100vh;
  background-image: linear-gradient(to right bottom, #31cce5, #444BC6, #E546D9);
}
#login-container-inner{
  width: 100%;
  min-width: 400px;
  max-width: 500px;
  margin: auto 20px;



  border-radius: 5px;
  position: relative;
  padding: 25px;


}
#login-container-card{
  overflow:hidden;
  background-color: #fff;
  border-radius:30px;
  box-shadow: 0 24px 64px rgb(38 33 74 / 10%);
  padding:15px 0px;
  margin: 0px 15px;

}
@media(max-width: 767px) {

  #login-container{
    justify-content: flex-start;
  }

  #login-container-inner{
    min-width: "100%";
  }

}



#signup-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: flex-start;
  -webkit-animation-duration: .25s;
  -moz-animation-duration: .25s;
  overflow-x: hidden;
  height: 100vh;

  background-image: linear-gradient(to right bottom, #31cce5, #444BC6, #E546D9);
}
#signup-container-inner{
  width: 100%;
  max-width: 500px;

  position: relative;
  background-color: #fff;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  margin: 15px;
  border-radius: 30px;
  box-shadow: 0 24px 64px rgb(38 33 74 / 10%);
  padding:15px 0px;
  align-self: stretch;
}
#signup-container-card{
  overflow:auto;
  align-self: stretch;
  padding-top: 25px;
  padding-bottom: 25px;

}

#signup-container-content{
  flex: 1;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  flex-flow: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

#signup-container-content-inner{
  padding: 25px;
  margin: 0px auto;
  max-width: 700px;
}
@media(max-width: 767px) {

  #signup-container{
    align-items: center;
    align-items: center;
    justify-content: center;
  }

  #signup-container-inner{
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
    align-self: flex-start;

  }
  #signup-container-content{
    flex: 0;
    display: none;
  }

}



#onboarding-container {


}

#step-through-container{
  min-width: 1200px;
}
#onboarding-container-inner {
  padding: 25px;
  margin: 0px auto;
  max-width: 1100px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  flex-flow: column;
  align-items: "center";
  justify-content: "center";
}

.mapboxgl-ctrl-attrib{
  display: none;
  height: 0px;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 15px #444BC6, 0 0 25px #444BC6;

  }
  33% {
    box-shadow: 0 0 15px #E546D9, 0 0 25px #E546D9;

  }
  66% {
    box-shadow: 0 0 15px #31cce5, 0 0 25px #31cce5;
  }
  100% {
    box-shadow: 0 0 15px #444BC6, 0 0 25px #444BC6;

  }
}

@keyframes floating {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}
@keyframes floating_big {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
.glowing-orb {
  border-radius: 50%;
  background: #fff;
  animation: glowing 10s infinite ease, floating 10s infinite ease-in-out;
  margin: 5px;
  width: 20px;
  height: 20px;
  transition:background .25s ease-in-out;

}
.glowing-orb.dark_mode{
  background: #1F2933;
}
.glowing-orb.big-orb{
  animation: glowing 10s infinite ease, floating_big 5s infinite ease-in-out;

}

.glowing-orb.big-orb:hover{
  background: rgba(68, 75, 198, 0.25);

}

.glowing-orb.not-active{
  animation: none;
  box-shadow: 0 0 15px #444BC6, 0 0 25px #444BC6;
  filter: grayscale(0.5);
  opacity: .35;
}


.mls-images .slick-prev{
  left: 0;
  z-index: 1;
  background: rgba(0,0,0,.1);
  margin-top: -5px;
  height: 100%;
  width: 30px;
  backdrop-filter: blur(2px);

}
.mls-images:not(.dark_mode) .slick-prev:before{
  color: #fff !important;
}
.mls-images .slick-prev:hover{
  background: rgba(0,0,0,.2);
}
.mls-images .slick-next{
  right: 0;
  z-index: 1;
  margin-top: -5px;
  background: rgba(0,0,0,.1);
  color: #000;

  height: 100%;
  width: 30px;
  backdrop-filter: blur(2px);
}

.mls-images:not(.dark_mode) .slick-next:hover{
  background: rgba(0,0,0,.2);

}

.mls-images .slick-next:before{
  color: #fff !important;
}


.morgages-carousel .slick-prev{
  left: 0;
  z-index: 1;
  margin-top: -5px;
  height: 100%;
  width: 30px;

}
.morgages-carousel:not(.dark_mode) .slick-prev:before{
  color: #004F64 !important;
}
.morgages-carousel .slick-prev:hover{
  background: rgba(0,0,0,.1);
}
.morgages-carousel .slick-next{
  right: 0;
  z-index: 1;
  margin-top: -5px;
  color: #000;

  height: 100%;
  width: 30px;
}

.morgages-carousel:not(.dark_mode) .slick-next:hover{
  background: rgba(0,0,0,.1);

}

.morgages-carousel .slick-next:before{
  color: #004F64 !important;
}


.contact-people .slick-prev{
  left: 0;
  z-index: 1;
  width: 30px;
  margin-top: 70px;
}
.contact-people:not(.dark_mode) .slick-prev:before{
  color:rgba(0,79,100, 0.5)  !important;
}
.contact-people:not(.dark_mode) .slick-prev:hover{
}
.contact-people .slick-next{
  right: 0;
  z-index: 1;
  color: #000;
  margin-top: 70px;
  width: 30px;
}

.contact-people:not(.dark_mode) .slick-next:hover{
}

.contact-people .slick-next:before{
  color:rgba(0,79,100, 0.5)  !important;
}

/* Keyframes defining the animation sequence */
@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Applying the animation to an element with class .animate */
.list-builder-progress-bar{
  animation: slideFromLeft 0.25s ease-out forwards; /* Change '1s' to your desired duration */
}

.progress-bars{
  box-shadow: 0 24px 64px rgba(38,33,74,.1);
}

ul.slick-dots{
  text-align:center !important;
}
.dm-dialer-dragger:hover{
  cursor: move;
}
.dm-dialer{
  position: fixed;
  top: 0;
  right: 0;

  width: 100%;
  box-shadow: 0 24px 64px rgba(38,33,74,.1);


}

.dm-dialer.onboarding{
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  box-shadow: 0 24px 64px rgba(38,33,74,.1);


}

@keyframes ring {
  0%, 100% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0);
  }
}

.ring-animation {
  animation: ring 1s infinite; /* Extended duration with a longer break */
  transform-origin: 50% 50%;
}

.dm-plan-select .dm-plan-select-card{
  margin: 5px;
}
.dm-plan-select {
  transition: all 0.1s ease-in-out;
  position: relative;
  z-index: 1;
  margin: 0px;
}

/* Styles for when the plan is selected */
.dm-plan-select.selected {
  transform: scale(1.05);

}

.dm-plan-select.selected .dm-plan-select-card{
  max-width: 500px !important;
}


@media(max-width: 767px){
  .dm-plan-select.selected {
    position: relative !important;
    z-index: 1;
    margin: 0px;
    transform: scale(1);
  }
}


.dm-plan-select-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  transition: opacity 0.1s ease-in-out;
  opacity: 1;
  pointer-events: all;
  z-index: 999;
}


.dm-plan-select-addons {
  display: none;
  opacity: 0;
  transition: opacity 0.25s, max-height 0.25s;
  max-height: 0; /* Ensure hidden by default */
  overflow: hidden;
}

/* Visible state with animation */
.dm-plan-select-addons.active {
  display: block;
  opacity: 1;
  max-height: 550px; /* Adjust as needed */
  transition: opacity 0.25s ease 0.25s, max-height 0.25s ease 0.25s;

}
@keyframes sparkle {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes glow {
  0%, 100% { box-shadow: 0 0 5px rgba(255, 215, 0, 0.6); }
  50% { box-shadow: 0 0 20px rgba(255, 215, 0, 0.8), 0 0 30px rgba(255, 215, 0, 0.6); }
}

.sparkle-effect {
  position: relative;
  display: inline-block;
  color: #fff;
  font-size: 24px;
  font-family: Arial, sans-serif;
  border-radius: 10px;
  overflow: hidden;
}

.sparkle-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.9), rgba(255,255,255,0) 70%);
  opacity: 0;
  pointer-events: none;
  animation: sparkle 5s linear infinite;

}

@keyframes sparkle {
  0% {
    transform: translate(-100%, -100%) rotate(45deg);
    opacity: 0;
  }
  20% {
    transform: translate(50%, 50%) rotate(45deg);
    opacity: 1;
  }
  40% {
    transform: translate(200%, 200%) rotate(45deg);
    opacity: 0;
  }


}

@keyframes colorful-sparkle {
  0% {
    box-shadow: 0 0 5px rgba(255, 182, 193, 0.3), 
                0 0 10px rgba(255, 182, 193, 0.3), 
                inset 0 0 5px rgba(255, 182, 193, 0.3);
  }
  25% {
    box-shadow: 0 0 7px rgba(173, 216, 230, 0.3), 
                0 0 12px rgba(173, 216, 230, 0.3), 
                inset 0 0 7px rgba(173, 216, 230, 0.3);
  }
  50% {
    box-shadow: 0 0 7px rgba(144, 238, 144, 0.3), 
                0 0 12px rgba(144, 238, 144, 0.3), 
                inset 0 0 7px rgba(144, 238, 144, 0.3);
  }
  75% {
    box-shadow: 0 0 10px rgba(221, 160, 221, 0.3), 
                0 0 15px rgba(221, 160, 221, 0.3), 
                inset 0 0 10px rgba(221, 160, 221, 0.3);
  }
  100% {
    box-shadow: 0 0 5px rgba(255, 182, 193, 0.3), 
                0 0 10px rgba(255, 182, 193, 0.3), 
                inset 0 0 5px rgba(255, 182, 193, 0.3);
  }
}

/* Apply the animation to the element */
.colorful-sparkle-border {
  border: 0px solid transparent;

  position: relative;
  animation: colorful-sparkle 6s infinite ease-in-out;
}

/* Adding small particle-like dots with pseudo-elements */
.colorful-sparkle-border::before, 
.colorful-sparkle-border::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: inherit;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 70%);
  background-size: 200% 200%;
  animation: sparkle-particles 4s infinite alternate;
}

/* Particle-like flicker effect */
@keyframes sparkle-particles {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}


.dm-white-overlay-modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(20px);
  transition: opacity 0.1s ease-in-out;
  opacity: 0;
  z-index: 99;
}

.scanned_property {
  /* Initial styles for the element */
  transition: transform 0.3s ease; /* Smooth transition */
}

.scanned_property:hover {
  transform: scale(1.05);
}
.list-builder-wrapper{
  transition: all 1s ease-in-out;
}
.do_not_show{
  display: none;
}


.StripeElement {
  display: block;
  margin: 0px;
  padding: 25px;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
  border: 0;
  outline: 0;
  font-size: 14px;
}


.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.workspace-logo-wrapper img{
  /*image is always white*/
  filter: brightness(0) invert(1);
}

@media (max-height: 750px) {
  #login-container {
    height: auto;
  }
}